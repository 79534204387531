// Libraries
import React, { useEffect, useState } from 'react';
import { Tabs, Spin } from 'antd';
import { Link, useParams } from 'react-router-dom';

// Component
import {
    Header,
    About,
    Videos,
    Portfolio,
    Gallery,
    Ads,
    Contact
} from '../my-account/components';

// Styles
import './style.css';
import useToken from '../../hooks/useToken';
import domainurl from '../../constants/domainurl';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';
import { useShareDispatch, useShareSelector } from '../../shared';
import { useMyAccountContext } from '../../context/MyAccount/MyAccount';


// Assets

const { TabPane } = Tabs;

const PublicProfile = (props) => {
    const { userRoleInfo, type } = useParams()
    const dispatcher = useShareDispatch();
    const { isLoadingUserRole } = useShareSelector(state => state.UserRoleDetails)
    const { isLoadingUserDetail } = useShareSelector(state => state.userTabDetail)
    const { setFitnessCenterType, setUserRolesInfo, userRolesInfo } = useMyAccountContext()
    const onChange = (key) => {
        console.log(key);
    };
    console.log(userRoleInfo)

    useEffect(() => {
        setFitnessCenterType(userRoleInfo)
        if (userRoleInfo) {
            let passingObje = {
                userRoledId: userRoleInfo,
                tab: 'about',
                readOnly: true,
                clear: true
            }

            dispatcher(actionsApi.getRolesDetails(passingObje))
            dispatcher(actionsApi.getUserTabDetails(passingObje))

        }


    }, [userRoleInfo])

    if (isLoadingUserRole) {
        return <div className='fs-spin'><Spin /></div>
    }

    return (
        <div className='fs-my-account'>
            <Header />
            <div className='fs-container'>
                <div className='fs-tabs'>
                    <Tabs defaultActiveKey="about" onChange={onChange}>
                        <TabPane tab="about" key="about">
                            {isLoadingUserDetail ? <div className='fs-spin'><Spin /></div>
                                :
                                <>
                                    <About />
                                </>
                            }

                        </TabPane>
                        <TabPane tab="video" key="video">
                            {isLoadingUserDetail ? <div className='fs-spin'><Spin /></div>
                                :
                                <>
                                    <Videos />
                                </>
                            }
                        </TabPane>
                        {
                            userRolesInfo && type != "model"
                                ?
                                <TabPane tab="portfolio" key="portfolio">
                                    {isLoadingUserDetail ? <div className='fs-spin'><Spin /></div>
                                        :
                                        <>
                                            <Portfolio />
                                        </>
                                    }
                                </TabPane> :
                                null
                        }
                        <TabPane tab="gallery" key="gallery">
                            {isLoadingUserDetail ? <div className='fs-spin'><Spin /></div>
                                :
                                <>
                                    <Gallery />
                                </>
                            }
                        </TabPane>
                        <TabPane tab="ads" key="ads">
                            {isLoadingUserDetail ? <div className='fs-spin'><Spin /></div>
                                :
                                <>  <Ads />
                                </>
                            }
                        </TabPane>
                        <TabPane tab="contact" key="contact">
                            <Contact />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default PublicProfile;