// Libraries
import React, { useEffect } from 'react';
import { Typography, Image, Popconfirm } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeft, ArrowRight, Plus, Edit3, Trash2 } from 'react-feather';
import Slider from "react-slick";

// Component

// Styles
import './style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Assets
import beforeImg from '../../../../assets/images/before-img.jpg';
import afterImg from '../../../../assets/images/after-img.jpg';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import useToken from '../../../../hooks/useToken';
import { useMyAccountContext } from '../../../../context/MyAccount/MyAccount';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import interceptor from '../../../../shared/interceptor';
import OpenNotification from '../../../../components/Notifications';

const { Title, Paragraph } = Typography;

const Portfolio = (props) => {
    const { userRoleInfo } = useParams()
    const dispatcher = useShareDispatch();
    const { token } = useToken();
    const { userRolesInfo } = useMyAccountContext()
    const { userPortfolio } = useShareSelector(state => state.userTabDetail);

    const editValue = (value, id) => {
        props.onEditPortfolio(value, id)
    }

    const deletePortfolio = (_id) => {
        interceptor.axiosDelete("DELETE", "/users/portfolioDelete/" + _id, {}, token).then(res => {
            console.log("resposne", res)
            OpenNotification("", "Portfolio Deleted Successfully")
            let passingObje = {
                userRoledId: userRolesInfo.roleId,
                token: token,
                tab: 'portfolio'
            }
            dispatcher(actionsApi.getUserTabDetails(passingObje))
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (!userPortfolio) {
            let passingObje = {
                userRoledId: userRoleInfo ? userRoleInfo : userRolesInfo.roleId,
                token: token,
                tab: 'portfolio',
                readOnly: userRoleInfo ? true : false,
            }
            dispatcher(actionsApi.getUserTabDetails(passingObje))
        }
        console.log(userPortfolio)

    }, [userPortfolio])

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <div className='fs-slick-arrows'><ArrowLeft size={18} /></div>,
        nextArrow: <div className='fs-slick-arrows'><ArrowRight size={18} /></div>,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false
                }
            },
        ]
    };

    return (
        <div className='fs-my-account fs-portfolio'>
            <div className='fs-about-head'>
                <Title level={2}>Success stories</Title>
                {!userRoleInfo && <div className='fs-circle-btn'>
                    <div to="#" onClick={() =>
                        editValue({ _id: null }, true)} className='fs-round-btn-large ant-btn ant-btn-primary'>
                        <Plus size={24} />
                    </div>
                </div>}
            </div>
            <div className='fs-portfolio-card fs-slick-arrows fs-mt-40'>
                <Slider {...settings}>

                    {((userPortfolio || {}).portfolio || []).map((value, key) => {
                        return (
                            <div key={key}>
                                <div className='fs-portfolio-outer fs-float-btn'>
                                    {!userRoleInfo && <div className='fs-circle-btn fs-absolute-btn'>
                                        <p onClick={() =>
                                            editValue(value, true)} className='fs-round-btn-small ant-btn ant-btn-primary fs-mr-15'>
                                            <Edit3 size={18} />
                                        </p>
                                        <Popconfirm
                                            title="Are you sure you want to delete.?"
                                            onConfirm={() => deletePortfolio(value._id)}

                                        >
                                            <p className='fs-round-btn-small ant-btn ant-btn-primary'>
                                                <Trash2 size={18} />
                                            </p>
                                        </Popconfirm>
                                    </div>}
                                    <div className='fs-portfolio-images'>
                                        <Image width="100%" src={value.beforePath} alt="" />
                                        <Image width="100%" src={value.afterPath} alt="" />
                                    </div>
                                    <div className='fs-portfolio-footer fs-mt-30'>
                                        <Paragraph>{value.description}</Paragraph>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </div>
    );
}

export default Portfolio;