// Libraries
import React from 'react';
import { Image, Typography } from 'antd';
// Component

// Styles
import './style.css';

// Assets

const { Title } = Typography;

const Header = (props) => {
    return (
        <div className='fs-page-header'>
            <div className='fs-container'>
                <div className='fs-block-header'>
                    <Image preview={false} src={props.icon} alt="" />
                    <Title level={1}>{props.title}</Title>
                    {props.subtitle && <Title level={3}>{props.subtitle}</Title>}
                </div>
            </div>
        </div>
    )
}

export default Header;