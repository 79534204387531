// Libraries
import React, { useState, useEffect } from 'react';
import { Select, Typography, Button, Form, Input, Radio, Rate, Upload, InputNumber } from 'antd';
import { Link } from 'react-router-dom';
import { Edit3, Trash2 } from 'react-feather';
// Styles
import './style.css';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import {
    fitnessCenterArray,
    skinTone,
    eyeColor,
    hairyLength,
    languageArray,
    modelingInterestArray,
    compensationArray,
    piercingsArray,
    experienceArray,
    activitiesArray,
    DietTypeArray,
    BodyTypeArray,
    HeightArray,
    ActivitiesArray,
    BodyTypeArrayForFM,
    HeightProArray
} from '../../../../data/fitnessCenters';
import interceptor from '../../../../shared/interceptor';
import useToken from '../../../../hooks/useToken';

// Assets

const { Option } = Select;
const { Title } = Typography;

const OPTIONS = ['Apples', 'Nails', 'Bananas', 'Helicopters'];
const BusinessProfile = () => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [fitnessCenters, setFitnessCenters] = useState([]);
    const { token } = useToken()
    const { userTabData } = useShareSelector(state => state.userTabDetail);

    const [modelingInterest, setModelingIntrest] = useState([])

    const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));
    const [value, setValue] = useState(1);
    const [fileList, setFileList] = useState(null);
    const [form] = Form.useForm();
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    const handlingModelInterest = (value) => {
        setModelingIntrest(value)
    }

    useEffect(() => {
        let fitnessProfessionalAbout = userTabData && userTabData.fitnessProfessionalAbout;
        if (fitnessProfessionalAbout && Object.keys(fitnessProfessionalAbout).length != 0) {
            if (fitnessProfessionalAbout.sponsors && fitnessProfessionalAbout.sponsors.length > 0) {
                let obj = []
                fitnessProfessionalAbout.sponsors.forEach(item => {
                    obj.push({
                        uid: item._id,
                        url: item.path,
                    })
                });
                setFileList(obj)
                form.setFieldsValue({
                    sponsors: obj,
                });
            }
        }
        interceptor.axiosGet("GET", "/users/getFitnessCenters", token).then(res => {
            console.log("resposne", res.data.result)
            if (res.data.status == "ok") {
                if (res.data.result) {
                    setFitnessCenters(res.data.result)
                }

            }
        }).catch(error => {
        })

    }, [])

    useEffect(() => {
        if (fileList) {
            console.log(fileList)
        }
    }, [fileList])

    return (
        <>
            <Title level={2} className="fs-mt-30">Personal</Title>
            <ul className='fs-about-list'>
                <li>
                    <div className='fs-about-title'>Name</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="persionalname"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input name',
                                    },
                                ]}
                            >
                                <Input placeholder='Please input your name' />
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Profile Url</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="profileUrl"
                                rules={[
                                    {
                                        message: 'Please input profile url',
                                    },
                                ]}
                            >
                                <Input placeholder='Please input profile url' />
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Gender</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="gender"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select gender',
                                    },
                                ]}
                            >
                                <Radio.Group onChange={onChange} value={value}>
                                    <Radio value={1}>Male</Radio>
                                    <Radio value={2}>Female</Radio>
                                    <Radio value={3}>Other</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Age</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="age"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input age',
                                    },
                                ]}
                            >
                                <InputNumber min={1} max={100} defaultValue={1} addonAfter="Years" style={{ width: "100%" }} />
                            </Form.Item>
                        </div>
                        {/* <div className='fs-fs-myaccount-sec'>
                            <Form.Item
                                name="ageType"
                                
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please Select age',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select age"
                                    defaultValue="Years" disabled
                                >
                                    <Option selected value="Years">Years</Option>
                                   
                                </Select>
                            </Form.Item>
                        </div> */}
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Height</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="height"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input height',
                                    },
                                ]}
                            >
                                <InputNumber min={1} defaultValue={5} />
                            </Form.Item>
                        </div>
                        <div className='fs-fs-myaccount-sec'>
                            <Form.Item
                                name="selectHeight"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select height',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select height"
                                    allowClear
                                >
                                    {HeightProArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Weight</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="weight"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input weight',
                                    },
                                ]}
                            >
                                <InputNumber min={1} defaultValue={1} />
                            </Form.Item>
                        </div>
                        <div className='fs-fs-myaccount-sec'>
                            <Form.Item
                                name="selectWeight"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select weight',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select weight"
                                    allowClear
                                >
                                    <Option value="Pounds">Pounds</Option>
                                    <Option value="kilos">Kilos</Option>

                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Diet Type</div>
                    <div className='fs-about-end'>

                        <div className='fs-fs-myaccount-sec'>
                            <Form.Item
                                name="DietType"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Diet Type',
                                    },
                                ]}
                            >
                                <Select
                                    // mode="multiple"
                                    placeholder="Select a Diet Type"
                                    showArrow
                                // value={selectedRole}
                                // onChange={handleRole}
                                >
                                    {DietTypeArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Body Type</div>
                    <div className='fs-about-end'>

                        <div className='fs-fs-myaccount-sec'>
                            <Form.Item
                                name="BodyType"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Body Type',
                                    },
                                ]}
                            >
                                <Select
                                    // mode="multiple"
                                    placeholder="Select a Body Type"
                                    showArrow
                                // value={selectedRole}
                                // onChange={handleRole}
                                >
                                    {BodyTypeArrayForFM.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        </div>
                    </div>
                </li>


                <li>
                    <div className='fs-about-title'>Activities</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="activities"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select activities',
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select activities"
                                    showArrow
                                    allowClear
                                    value={selectedItems}
                                    onChange={setSelectedItems}
                                >
                                    {ActivitiesArray.map((item) => (
                                        <Select.Option key={item.key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>My Story/About me</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="StoryAboutme"
                            >
                                <Input.TextArea placeholder='Please input about me' />
                            </Form.Item>
                        </div>
                    </div>
                </li>

                <li>
                    <div className='fs-about-title'>Fitness Center</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="fitnessCenters"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Center',
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select Center"
                                    showArrow
                                    allowClear
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {fitnessCenters && fitnessCenters.map((item) => (
                                        <Select.Option key={item._id} value={item._id}>
                                            {item.businessName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>

                {/* <li className='fs-align-items-top'>
                    <div className='fs-about-title'>Product Reviews</div>
                    <div className='fs-about-end'>
                        <div className='fs-helper-block'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="product"
                                >
                                    <Input.TextArea placeholder='Please input product' />
                                </Form.Item>
                            </div>
                            <div className='fs-fs-myaccount-sec'>
                                <Form.Item
                                    name="reviews">
                                    <Input.TextArea placeholder='Please input reviews' />
                                </Form.Item>
                            </div>
                            <div className='fs-fs-myaccount-sec'>
                                <Rate />
                            </div>
                            <div className='fs-circle-btn'>
                                <div to="#" className='fs-round-btn-large ant-btn ant-btn-primary'>
                                    <Trash2 size={18} />
                                </div>
                            </div>
                        </div>
                        <div className='fs-helper-block'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="product"
                                >
                                    <Input.TextArea placeholder='Please input product' />
                                </Form.Item>
                            </div>
                            <div className='fs-fs-myaccount-sec'>
                                <Form.Item
                                    name="reviews">
                                    <Input.TextArea placeholder='Please input reviews' />
                                </Form.Item>
                            </div>
                            <div className='fs-fs-myaccount-sec'>
                                <Rate />
                            </div>
                            <div className='fs-circle-btn'>
                                <div to="#" className='fs-round-btn-large ant-btn ant-btn-primary'>
                                    <Trash2 size={18} />
                                </div>
                            </div>
                        </div>
                        <Button type="primary" htmlType="submit" className='fs-mt-15'>Add New Product Review</Button>
                    </div>
                </li> */}
                <li className='fs-align-items-top'>
                    <div className='fs-about-title'>Sponsor Images</div>
                    <div className='fs-about-end'>
                        {/* <div className='fs-helper-block'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="sponsorName"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please input sponsor name',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Please input sponsor name' />
                                </Form.Item>
                            </div>
                            <div className='fs-fs-myaccount-sec'>
                                <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture"
                                    maxCount={1}
                                >
                                    <Button className='fs-upload'>Choose File</Button>
                                </Upload>
                            </div>
                            <div className='fs-circle-btn'>
                                <div to="#" className='fs-round-btn-large ant-btn ant-btn-primary'>
                                    <Trash2 size={18} />
                                </div>
                            </div>
                        </div> */}
                        <div className='fs-helper-block'>
                            {/* <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="sponsorName"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please input sponsor name',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Please input sponsor name' />
                                </Form.Item>
                            </div> */}
                            <div className='fs-fs-myaccount-sec'>
                                <Form.Item

                                    name="sponsors"
                                >
                                    <Upload
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture"
                                        multiple={true}
                                        fileList={fileList}
                                        onChange={({ fileList: newFileList }) => {
                                            setFileList(newFileList);
                                        }}
                                        beforeUpload={(file) => {
                                            return false
                                        }}
                                    >
                                        <Button className='fs-upload'>Choose File</Button>
                                    </Upload>
                                </Form.Item>
                            </div>
                            {/* <div className='fs-circle-btn'>
                                <div to="#" className='fs-round-btn-large ant-btn ant-btn-primary'>
                                    <Trash2 size={18} />
                                </div>
                            </div> */}
                        </div>
                        {/* <div className='fs-fs-myaccount-sec'>
                            <Button type="primary" >Add New Sponsor Image</Button>
                        </div> */}
                    </div>
                </li>
            </ul>
        </>
    );
}


export default BusinessProfile;
