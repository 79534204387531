// Libraries
import React, { useEffect, useState } from 'react';
import { Row, Col, Select, Typography, Tag, Modal, Popconfirm } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { Plus, Edit3, Youtube, Trash2 } from 'react-feather';
import ReactPlayer from 'react-player/lazy';

// Component

// Styles
import './style.css';
import { AddVideo } from '..';
import EditVideo from '../EditVideo';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import interceptor from '../../../../shared/interceptor';
import domainurl from '../../../../constants/domainurl';
import useToken from '../../../../hooks/useToken';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import { useMyAccountContext } from '../../../../context/MyAccount/MyAccount';

// Assets

const { Option } = Select;
const { Title } = Typography;

const Videos = (props) => {
    const { userRoleInfo } = useParams()
    const [visible, setVisible] = useState(false);
    const dispatcher = useShareDispatch();
    const { userRolesInfo } = useMyAccountContext()
    const { userVideos } = useShareSelector(state => state.userTabDetail);
    const { token } = useToken();
    const [videoArray, setVideoArray] = useState([])

    const editValue = (value, id) => {
        props.onEditVideo(value, id)
    }

    const deleteVideo = (_id) => {
        interceptor.axiosDelete("DELETE", "/users/videoDelete/" + _id, {}, token).then(res => {
            console.log("resposne", res)
            let passingObje = {
                userRoledId: userRolesInfo.roleId,
                token: token,
                tab: 'video'
            }
            dispatcher(actionsApi.getUserTabDetails(passingObje))
        }).catch(error => {
            console.log(error)
        })
    }



    useEffect(() => {
        if (!userVideos) {
            let passingObje = {
                userRoledId: userRoleInfo ? userRoleInfo : userRolesInfo.roleId,
                token: token,
                tab: 'video',
                readOnly: userRoleInfo ? true : false,
            }
            dispatcher(actionsApi.getUserTabDetails(passingObje))
        }

    }, [userVideos])

    return (
        <div className='fs-my-account fs-videos'>
            <div className='fs-about-head'>
                <Title level={2}>Videos</Title>
                {!userRoleInfo && <div className='fs-circle-btn' >
                    <div onClick={() =>
                        editValue({ _id: null }, true)} className='fs-round-btn-large ant-btn ant-btn-primary'>
                        <Plus size={24} />
                    </div>
                </div>}
            </div>
            <Row gutter={[25]}>
                {
                    ((userVideos || {}).videos || []).map((value, key) => {
                        return (
                            <Col xs={24} md={12}>
                                <div className='fs-video fs-card'>
                                    <div className='player-wrapper'>
                                        <ReactPlayer
                                            className='react-player'
                                            width='100%'
                                            height='100%'
                                            playIcon={<Youtube size={70} />}
                                            controls
                                            key={key}
                                            id={key}
                                            url={value.url}
                                            light={false}
                                        />
                                    </div>
                                    <div className='fs-video-footer'>
                                        <div className='fs-video'>
                                            <Tag color="#FF1A1A">{value.category}</Tag>
                                        </div>
                                        {!userRoleInfo && <div className='fs-circle-btn'>
                                            <p onClick={() =>
                                                editValue(value, true)} className='fs-round-btn-small ant-btn ant-btn-primary fs-mr-15'>
                                                <Edit3 size={18} />
                                            </p>
                                            <Popconfirm
                                                title="Are you sure you want to delete.?"
                                                onConfirm={() => deleteVideo(value._id)}
                                                onVisibleChange={() => console.log('visible change')}
                                            >
                                                <p className='fs-round-btn-small ant-btn ant-btn-primary'>
                                                    <Trash2 size={18} />
                                                </p>
                                            </Popconfirm>
                                        </div>}
                                    </div>
                                </div>
                            </Col>
                        )

                    })
                }

            </Row>
            {/* <Row gutter={[25]}>

                <Col xs={24} md={12}>
                    <div className='fs-video fs-card'>
                        <div className='player-wrapper'>
                            <ReactPlayer
                                className='react-player'
                                width='100%'
                                height='100%'
                                playIcon={<Youtube size={70} />}
                                controls
                                url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                            // light={true}
                            />
                        </div>
                        <div className='fs-video-footer'>
                            <div className='fs-video'>
                                <Tag color="#FF1A1A">WORK OUT</Tag>
                            </div>
                            <div className='fs-circle-btn'>
                                <Link to="#" className='fs-round-btn-small ant-btn ant-btn-primary fs-mr-15'>
                                    <Edit3 size={18} />
                                </Link>
                                <Link to="#" className='fs-round-btn-small ant-btn ant-btn-primary'>
                                    <Trash2 size={18} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className='fs-video fs-card'>
                        <div className='player-wrapper'>
                            <ReactPlayer
                                className='react-player'
                                width='100%'
                                height='100%'
                                playIcon={<Youtube size={70} />}
                                controls
                                url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                            // light={true}
                            />
                        </div>
                        <div className='fs-video-footer'>
                            <div className='fs-video'>
                                <Tag color="#FF1A1A">WORK OUT</Tag>
                            </div>
                            <div className='fs-circle-btn'>
                                <Link to="#" className='fs-round-btn-small ant-btn ant-btn-primary fs-mr-15'>
                                    <Edit3 size={18} />
                                </Link>
                                <Link to="#" className='fs-round-btn-small ant-btn ant-btn-primary'>
                                    <Trash2 size={18} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className='fs-video fs-card'>
                        <div className='player-wrapper'>
                            <ReactPlayer
                                className='react-player'
                                width='100%'
                                height='100%'
                                playIcon={<Youtube size={70} />}
                                controls
                                url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                            // light={true}
                            />
                        </div>
                        <div className='fs-video-footer'>
                            <div className='fs-video'>
                                <Tag color="#FF1A1A">WORK OUT</Tag>
                            </div>
                            <div className='fs-circle-btn'>
                                <Link to="#" className='fs-round-btn-small ant-btn ant-btn-primary fs-mr-15'>
                                    <Edit3 size={18} />
                                </Link>
                                <Link to="#" className='fs-round-btn-small ant-btn ant-btn-primary'>
                                    <Trash2 size={18} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row> */}

        </div>
    );
}

export default Videos;