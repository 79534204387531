import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import OpenNotification from "../../../components/Notifications";
import useToken from "../../../hooks/useToken";
import interceptor from "../../../shared/interceptor";

export const getAllActiveBlogs = createAsyncThunk(
    "blogs/getAllActive",
    async (object, { dispatch, getState }) => {
        dispatch(gettingResponse())
        interceptor.axiosGet("get", "/blogs/getActive", object, {}).then(res => {
            if (res.status == 200) {
                dispatch(gettingResponseSuccess(res.data.result))

            } else {
                dispatch(gettingResponseSuccess({}))
                OpenNotification("topRight", res.message)
            }
        }).catch(error => {
            dispatch(gettingResponseError(error))
            OpenNotification("topRight", error)

        })

    },
);


const blogsSlice = createSlice({
    name: "blogs",
    initialState: {

        blogs: null,
        status: "idle",
        isLoadingblogs: false,
        error: null,
        isError: false
    },
    reducers: {
        gettingResponseError: (state, action) => {
            state.status = "error";
            state.blogs = null;
            state.error = action.payload;
            state.isLoadingblogs = false;
            state.isError = true;

        },
        gettingResponseSuccess: (state, action) => {

            state.status = "success";
            state.blogs = action.payload;
            state.isLoadingblogs = false;
            state.isError = false;
        },
        gettingResponse: (state, action) => {
            state.status = "loading";
            state.isLoadingblogs = true;
            state.blogs = null;
            state.isError = false;
        },
    },

});


// Action creators are generated for each case reducer function
export const { gettingResponse, gettingResponseError, gettingResponseSuccess } =
    blogsSlice.actions;

export default blogsSlice.reducer;
