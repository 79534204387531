// Libraries
import React, { useState, useEffect } from 'react';
import { Select, Typography, Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import { Plus, X } from 'react-feather';

// Component

// Styles
import './style.css';
import useToken from '../../../../hooks/useToken';
import OpenNotification from '../../../../components/Notifications';
import { useMyAccountContext } from '../../../../context/MyAccount/MyAccount';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import { useShareDispatch } from '../../../../shared';
import { videoCategoryArray } from '../../../../data/fitnessCenters';
import domainurl from '../../../../constants/domainurl';

// Assets

const { Option } = Select;
const { Title } = Typography;

const EditVideo = (props) => {
    const dispatcher = useShareDispatch();
    const { addingVideoStatus, updateList } = props
    const [form] = Form.useForm();
    const { userLoginObject, token } = useToken();
    const { userRolesInfo } = useMyAccountContext()
    const [isSubmitting, setIsSubmitting] = useState();
    const [videoModel, setVideoModel] = useState();
    const onFinish = (values) => {
        console.log('Success:', values);
        onAddVideo(values);
    };
    const onUpdate = () => {
        props.onUpdateVideo(false)
    }
    useEffect(() => {
        setVideoModel(props.model)
        if (props.model._id) {
            form.setFieldsValue({
                videoLink: `${props.model.url}`,
                videoCategory: `${props.model.category}`,

            });
        }
        else {
            form.resetFields();
        }

    }, [addingVideoStatus])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onAddVideo = (value) => {
        setIsSubmitting(true)
        let bodyJson = {
            "data": [
                {

                    "_id": !props.model._id ? "-1" : props.model._id,
                    "url": value.videoLink,
                    "category": value.videoCategory
                }
            ]

        }
        fetch(domainurl+"/users/addEditVideo/" + userRolesInfo.roleId, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` },
            body: JSON.stringify(bodyJson)

        }).then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.status == "ok") {
                    OpenNotification("", "Video link successfully added.")
                    props.onUpdateVideo(false)
                    let passingObje = {
                        userRoledId: userRolesInfo.roleId,
                        token: token,
                        tab:'video'
                    }
                    dispatcher(actionsApi.getUserTabDetails(passingObje))
                }

                setIsSubmitting(false)
            }).catch(err => {
                setIsSubmitting(false)
            })
    }

    return (
        <div className='fs-my-account fs-videos'>
            <div className='fs-about-head'>
                <Title level={2}>Edit Video</Title>
                {/* <div className='fs-circle-btn'>
                    <div to="#" className='fs-round-btn-large ant-btn ant-btn-primary'>
                        <X size={24} />
                    </div>
                </div> */}
            </div>
            <div className='fs-add-video'>
                <Form
                    // name="myAccountAboutForm"
                    onFinish={onFinish}
                    form={form}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <ul className='fs-about-list'>
                        <li>
                            <div className='fs-about-title'>
                                <Form.Item
                                    name="videoLink"
                                    className='fs-mb-0'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input video link',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Please input video link' />
                                </Form.Item>
                            </div>
                            <div className='fs-about-end'>
                                <Form.Item
                                    name="videoCategory"
                                    className='fs-mb-0'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select video category',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select video category"
                                    >
                                        {videoCategoryArray.map((item, key) => (
                                            <Select.Option key={key} value={item.name}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        </li>
                    </ul>
                    <div className='fs-btn-group fs-mt-20'>
                        <Button type="primary" htmlType="submit" loading={isSubmitting} className='fs-mr-15 fs-black'>Save</Button>
                        <Button type="primary" onClick={onUpdate} >Cancel</Button>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default EditVideo;