// Libraries
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Input, Select, Button, Image, Table } from 'antd';

// Component

// Styles
import './style.css';

// Assets
import image from '../../../../assets/images/hero.jpg';
import domainurl from '../../../../constants/domainurl';
import { FitnessCenterTypeArray, SpecialtiesArray } from '../../../../data/fitnessCenters';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { gettingFCList } from '../../../../redux-toolkit/actions/user/userDetail';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import useToken from '../../../../hooks/useToken';

const { Option } = Select;

const columns = [
    {
        title: 'Fitness Center',
        key: 'fitnessCenter',
        render: (value) => <Image preview={false} src={value && value.imagePath ? value.imagePath : image} alt="" />
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => <Link to={`/public-profile/center/${record.userRoleInfo}`}>{text}</Link>,

    },

    {
        title: 'Type',
        dataIndex: 'fitnessCenterType',
        key: 'fitnessCenterType',
    },
    {
        title: 'Open Since',
        dataIndex: 'openSince',
        key: 'openSince',
    },
    // {
    //     title: 'Operating Hours',
    //     dataIndex: 'timeRange',

    //     key: 'timeRange',
    //     render: (value) => (
    //         <>


    //             <table>
    //                 {
    //                     value && value.length > 0 && value.map(item => {
    //                         return (
    //                             <tr>
    //                                 <th className='fs-about-title'>{item.day}</th>
    //                                 <td>{item.value ? (moment(item.value[0]).format('h:mm:ss a') + " - " + moment(item.value[1]).format('h:mm:ss a'))
    //                                     : 'Close'}
    //                                 </td>
    //                             </tr>

    //                         )
    //                     })
    //                 }
    //             </table>
    //             {/* {value && <p>{moment(value.split(',')[0]).format('h:mm a') + " - " + moment(value.split(',')[1]).format('h:mm a')}</p>} */}
    //         </>
    //     ),
    // },
    {
        title: 'Specialties',
        dataIndex: 'specialties',
        key: 'specialties',
    },
    // {
    //     title: 'Rating',
    //     dataIndex: 'rating',
    //     key: 'rating',
    // },
];

const FitnessCenters = () => {
    const { fcList } = useShareSelector(state => state.UserRoleDetails)
    const { userLoginObject } = useToken();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const dispatcher = useShareDispatch();

    const [form] = Form.useForm();

    useEffect(() => {

        getFCList(form.getFieldValue());
    }, [fcList]);


    const getFCList = (values) => {
        if (userLoginObject) {
            let defaultReole = userLoginObject.data.defaultRole;
            const filteredResult = userLoginObject && userLoginObject.data.userRolesInfo && userLoginObject.data.userRolesInfo.find((e) => e.role == defaultReole);
            values.loginUserId = filteredResult._id
        }
        setIsLoading(true)
        fetch(domainurl + '/users/getFCUserList', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status == "ok") {

                    // res.result.forEach(item => {
                    //     try {
                    //         item.timeRange = JSON.parse(item.timeRange)
                    //     }
                    //     catch {
                    //         item.timeRange = [
                    //             {
                    //                 day: 'Monday',
                    //                 value: item.timeRange.split(','),
                    //             },
                    //             {
                    //                 day: 'Tuesday',
                    //                 value: item.timeRange.split(','),
                    //             },
                    //             {
                    //                 day: 'Wednesday',
                    //                 value: item.timeRange.split(','),
                    //             },
                    //             {
                    //                 day: 'Thursday',
                    //                 value: item.timeRange.split(','),
                    //             },
                    //             {
                    //                 day: 'Friday',
                    //                 value: item.timeRange.split(','),
                    //             },
                    //             {
                    //                 day: 'Saturday',
                    //                 value: item.timeRange.split(','),
                    //             },
                    //             {
                    //                 day: 'Sunday',
                    //                 value: item.timeRange.split(','),
                    //             },
                    //         ];
                    //     }
                    // });
                    setData(res.result)
                    // dispatcher(gettingFCList(res.result))
                }
                setIsLoading(false)

            })
            .catch((error) => {
                setIsLoading(false);
            });
    }

    const onFinish = (values) => {
        console.log('Success:', values);
        getFCList(values)
    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='fs-fitness'>
            <div className='fs-container'>
                <Form
                    name="fitnessProForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                >
                    <Row gutter={25}>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="name"
                            >
                                <Input placeholder='Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="fitnessCenterType"
                            >
                                <Select
                                    placeholder="Fitness Center Type"
                                    allowClear
                                    showArrow
                                >
                                    {FitnessCenterTypeArray.map((item) => (
                                        <Select.Option key={item.name} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                                {/* <div id="fitnessType"></div> */}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="location"
                            >
                                <Input placeholder='Location' />
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="experience"
                            >

                                <Select
                                    placeholder="Experience"
                                    showArrow
                                    allowClear
                                >
                                    <Option value="5">1-5 years</Option>
                                    <Option value="10">5-10 years</Option>
                                    <Option value="20">20+ years</Option>
                                </Select>
                            </Form.Item>
                        </Col> */}
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="specialities"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    showArrow
                                    placeholder="Specialities"
                                >
                                    {SpecialtiesArray.map((item) => (
                                        <>
                                            {
                                                item.name !== 'Others' && item.name !== 'All' &&
                                                < Select.Option key={item.name} value={item.name} >
                                                    {item.name}
                                                </Select.Option>

                                            }
                                        </>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='fs-right'>
                        <Button type="default" className='fs-mr-15' onClick={() => { form.resetFields(); }}>Reset</Button>
                        <Button type="primary" htmlType="submit">Search Fitness Centers</Button>
                    </div>
                </Form>
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={isLoading}
                    scroll={{
                        x: 1000,
                    }}
                    className="fs-pros fs-center" />
            </div>
        </div >
    );
}

export default FitnessCenters;