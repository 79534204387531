
import { getRolesDetails,setFitnessDirectory } from "./user/UserRoleDetails";
import { getUserTabDetails } from "./user/userDetail";
import { getHomePageDetail } from "./home/homePage";
import { getAllActiveSponsers } from "./home/sponsor";
import { getAllActiveAmbassadors } from "./home/ambassadors";
import { getAllActiveBlogs} from "./home/blogs";

export const actionsApi = {
    getRolesDetails,
    getUserTabDetails,
    getHomePageDetail,
    getAllActiveSponsers,
    getAllActiveAmbassadors,
    setFitnessDirectory,
    getAllActiveBlogs

}
