// Libraries
import React, { useState } from 'react';
import { Image, Row, Col, Typography, Button, Form, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

// Component
import useToken from '../../hooks/useToken';

// Styles
import './style.css';

// Assets
import AuthImg from '../../assets/images/auth-img.jpg';
import Logo from '../../assets/images/logo.png';
import domainurl from '../../constants/domainurl';
import OpenNotification from '../../components/Notifications';

const { Title, Text } = Typography;

const Signin = () => {

    const navigate = useNavigate();
    const { saveLoginUser } = useToken();
    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);

    const onFinish = (values) => {
        console.log('Success:', values);
        signInUser(values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const signInUser = (values) => {
        setIsLoading(true);
        let bodyJson = {
            "email": values.email,
            "password": values.password,
        }

        fetch(domainurl + '/users/authenticate', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyJson)
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status == "ok") {
                    saveLoginUser(res.result);
                    navigate('/myaccount');
                }
                else {
                    if (res.status == "error") {
                        form.setFields([
                            {
                                name: 'password',
                                errors: [res.message],
                            },
                            {
                                name: 'email',
                                errors: [res.message],
                            },
                        ]);

                        OpenNotification("", res.message)

                    }
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }

    return (
        <div className='fs-auth-container'>
            <Row align='middle'>
                <Col xs={24} md={12} className="fs-img-col">
                    <div className='fs-auth-img'>
                        <Image
                            preview={false}
                            src={AuthImg}
                            alt="Auth Image" />
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className='fs-auth-content'>
                        <Link to="/">
                        <div className='fs-auth-logo'>
                            <Image
                                preview={false}
                                src={Logo}
                                alt="Fitstar" />
                        </div>
                        </Link>
                        <div className='fs-auth-card'>
                            <div className='fs-auth-card-header'>
                                <Title level={2} className="fs-auth-card-header-title">Sign In</Title>
                                <Text>Don't have an account yet? <Link to='/signup'>Sign up</Link></Text>
                            </div>
                            <Form
                                className='fs-auth-form'
                                layout="vertical"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                form={form}
                            >
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'Please input valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please input your E-mail!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password',
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder="Password" />
                                </Form.Item>
                                <Link to='/forgot-password' className='fs-forgot-pass'>Forgot Password?</Link>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={isLoading}>sign in</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>

        </div>
    );
}

export default Signin;