// Libraries
import React, { useState, useEffect } from 'react';
import { Select, Typography, Button, Form, Input, Radio, Rate, Upload, InputNumber } from 'antd';
import { Edit3, Trash2 } from 'react-feather';

// Component
import {
    HeightArray,
    WeightArray,
    BodyTypeArray,
    EthnicityArray,
    SkinToneArray,
    EyeColorArray,
    HairLengthArray,
    TattoosArray,
    PiercingsArray,
    LanguagesArray,
    ModelingInterestsArray,
    CompensationArray,
    ActivitiesArray,
    experienceArray
} from '../../../../data/fitnessCenters';

// Styles
import './style.css';

// Assets

const { Option } = Select;
const { Title } = Typography;

const EditAboutModel = () => {

    // const [selectedLanguages, setSelectedLanguages] = useState([]);
    // const [selectedInterests, setSelectedInterests] = useState([]);
    // const [selectedActivities, setSelectedActivities] = useState([]);
    // const languageFilteredOptions = OPTIONS.filter((o) => !selectedLanguages.includes(o));
    // const interestsFilteredOptions = OPTIONS.filter((o) => !selectedInterests.includes(o));
    // const activitiesFilteredOptions = OPTIONS.filter((o) => !selectedActivities.includes(o));

    const onFinish = (values) => {
        console.log('Success:updated', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='fs-my-account fs-about'>
            <Form
                name="editAboutModel"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className='fs-about-head'>
                    <Title level={2}>Key Info</Title>
                    <div className='fs-circle-btn'>
                        <div to="#" className='fs-round-btn-large ant-btn ant-btn-primary'>
                            <Trash2 size={24} />
                        </div>
                    </div>
                </div>
                <ul className='fs-about-list'>
                    <li>
                        <div className='fs-about-title'>Name</div>
                        <div className='fs-about-end'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="name"
                                >
                                    <Input placeholder='Please input your name' />
                                </Form.Item>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Profile Url</div>
                        <div className='fs-about-end'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="profileUrl"
                                >
                                    <Input placeholder='Please input your profile URL' />
                                </Form.Item>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Gender</div>
                        <div className='fs-about-end'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="gender"
                                >
                                    <Radio.Group>
                                        <Radio value={1}>Male</Radio>
                                        <Radio value={2}>Female</Radio>
                                        <Radio value={3}>Other</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Age</div>
                        <div className='fs-about-end'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="age"
                                >
                                    <Input placeholder='Please input age' type="integer" />
                                </Form.Item>
                            </div>
                            <div className='fs-fs-myaccount-sec'>
                                <Form.Item
                                    name="ageType"
                                >
                                    <Select
                                        placeholder="Select age"
                                        allowClear
                                    >
                                        <Option value="Years">Years</Option>

                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Height</div>
                        <div className='fs-about-end'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="height"
                                >
                                    <InputNumber min={0} defaultValue={0} />
                                </Form.Item>
                            </div>
                            <div className='fs-fs-myaccount-sec'>
                                <Form.Item
                                    name="selectHeight"
                                >
                                    <Select
                                        placeholder="Select height"
                                        allowClear
                                        showSearch
                                    >
                                        {HeightArray.map((item, key) => (
                                            <Select.Option key={key} value={item.key}>
                                                {item.name}
                                            </Select.Option>
                                        ))}

                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Weight</div>
                        <div className='fs-about-end'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="weight"
                                >
                                    <InputNumber min={0} defaultValue={0} />
                                </Form.Item>
                            </div>
                            <div className='fs-fs-myaccount-sec'>
                                <Form.Item
                                    name="selectWeight"
                                >
                                    <Select
                                        placeholder="Select weight"
                                        allowClear
                                        showSearch
                                    >
                                        {WeightArray.map((item, key) => (
                                            <Select.Option key={key} value={item.key}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Body Type</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="BodyType"
                            >
                                <Select
                                    placeholder="Select a Body Type"
                                    allowClear
                                    showSearch
                                >
                                    {BodyTypeArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Ethnicity</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="ethnicity"
                            >
                                <Select
                                    placeholder="Select Ethnicity"
                                    allowClear
                                    showSearch
                                >
                                    {EthnicityArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Skin Tone</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="skinTone"
                            >
                                <Select
                                    placeholder="Select skin tone"
                                    allowClear
                                    showSearch
                                >
                                    {SkinToneArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Eye Color</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="eyeColor"
                            >
                                <Select
                                    placeholder="Select eye color"
                                    allowClear
                                    showSearch
                                >
                                    {EyeColorArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Hair Length</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="hairLength"
                            >
                                <Select
                                    placeholder="Select hair length"
                                    allowClear
                                    showSearch
                                >
                                    {HairLengthArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Tattoos</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="tattoos"
                            >
                                <Select
                                    placeholder="Select tattoos"
                                    allowClear
                                    showSearch
                                >
                                    {TattoosArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Piercings</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="piercings"
                            >
                                <Select
                                    placeholder="Select piercings"
                                    allowClear
                                    showSearch
                                >
                                    {PiercingsArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Experience</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="experience"
                            >
                                <Select
                                    placeholder="Select experience"
                                    allowClear
                                    showSearch
                                >
                                    {experienceArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Languages</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="languages"
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select languages"
                                    showArrow
                                    allowClear
                                    showSearch
                                >
                                    {LanguagesArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Working With Media</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="workingWithMedia"
                            >
                                <Radio.Group>
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={2}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Modeling Interests</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="modelingInterests"
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select modeling interests"
                                    showArrow
                                    allowClear
                                    showSearch
                                >
                                    {ModelingInterestsArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Compensation</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="compensation"
                            >
                                <Select
                                    placeholder="Select compensation"
                                    allowClear
                                    showSearch
                                >
                                    {CompensationArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Notes About Compensation</div>
                        <div className='fs-about-end'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="notesAboutCompensation"
                                >
                                    <Input placeholder='Please input notes about compensation' />
                                </Form.Item>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Activities</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="modelingInterests"
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select activities"
                                    showArrow
                                    allowClear
                                    showSearch
                                >
                                    {ActivitiesArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>My Story/About Me</div>
                        <div className='fs-about-end'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="myStory"
                                >
                                    <Input.TextArea placeholder='Please input my story' />
                                </Form.Item>
                            </div>
                        </div>
                    </li>
                </ul>
                <Button type="primary" htmlType="submit" className="fs-mt-30">update</Button>
            </Form>
        </div>
    );
}

export default EditAboutModel;