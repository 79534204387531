// Libraries
import React, { useEffect, useState } from 'react';
import { Layout, Image, Menu, Dropdown, Button, Avatar, Row, Col, Typography, Modal, Form, Input, Select } from 'antd';
import { Outlet, Link, useNavigate, useParams } from 'react-router-dom';
import { Facebook, Twitter, Youtube, Linkedin, ChevronDown, Menu as HamBurger, X } from 'react-feather';

// Component
import { EmailIcon, PhoneIcon } from '../../assets/icons/Icons';
import useToken from '../../hooks/useToken';

// Styles
import './style.css';

// Assets
import Logo from '../../assets/images/logo.png';
import User from '../../assets/images/user.jpg';
import { useShareDispatch, useShareSelector } from '../../shared';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';
import interceptor from '../../shared/interceptor';
import OpenNotification from '../Notifications';
import BlogDetail from '../../pages/blog-detail';
const ROLE_OPTIONS = [{ "center": 'Fitness Center', "key": "FC" }, { "center": 'Fitness Model', "key": "FM" }, { "center": 'Fitness Professional', "key": "FP" }];

const { Title } = Typography;

const { Header, Content, Footer } = Layout;



const AppLayout = () => {
    const { REACT_APP_BASE_URL_App } = process.env
    const mainMenu = [
        {
            label: (
                <a href={`${REACT_APP_BASE_URL_App}/about-us`}>About Us</a>
            ),
            key: 'about',
        },
        {
            label: (
                <a href={`${REACT_APP_BASE_URL_App}/fitness-directory`}>Fitness Directory</a>
            ),
            key: 'fitness',
        },
        {
            label: (
                <a href="/">Blog</a>
            ),
            key: 'blog',
        },
        {
            label: (
                <a href={`${REACT_APP_BASE_URL_App}/contact-us`}>Contact Us</a>
            ),
            key: 'contact',
        },
    ];
    const { userRoleInfo, type } = useParams()
    const { userRoleData, userRolesArray } = useShareSelector(state => state.UserRoleDetails)
    const navigate = useNavigate();
    const { token, removeToken, userLoginObject, } = useToken();
    const handleLogout = () => {
        removeToken();
        navigate(`${REACT_APP_BASE_URL_App}/signin`);
    }
    const [form] = Form.useForm();
    const { isLoadinghomePage, homePageData } = useShareSelector(state => state.homePageDetail)
    const dispatcher = useShareDispatch();
    const [footer, setFooter] = useState(null);
    const [userModel, setUserModel] = useState(null);
    const [isUpdating, setIsupdating] = useState(false);
    const [isUpdatingPassword, setIsupdatingPassword] = useState(false);
    const [isUpdatingRole, setIsupdatingRole] = useState(false);
    const [isModalVisible, setisModalVisible] = useState(false);
    const [roleModalVisible, setRoleModalVisible] = useState(false);
    const [roleArray, setRoleArray] = useState([]);
    const handleUserCancel = () => {
        setisModalVisible(false);
    };

    const handleRoleCancel = () => {
        setRoleModalVisible(false);
    };

    const showUserModal = () => {
        setisModalVisible(true);
    }

    const showRoleModal = () => {
        let val = ROLE_OPTIONS.filter(({ key: id1 }) => !userRolesArray.some(({ role: id2 }) => id2 === id1));
        console.log(val)
        setRoleArray(val)
        setRoleModalVisible(true);
    }



    const checkPassword = (rule, value, callback) => {
        let pass = form.getFieldValue('newPassword')
        let cPass = form.getFieldValue('confirmPassword')
        if (pass && cPass && pass !== cPass) {
            // callback("The passwords don't match");
            form.setFields([
                {
                    name: 'newPassword',
                    errors: ["The passwords don't match"],
                },
            ]);
        } else {
            form.setFields([
                {
                    name: 'newPassword',
                    errors: [],
                },
            ]);
        }
    };


    useEffect(() => {
        console.log(userRoleData)
        console.log(userLoginObject)
        if (userRoleInfo && type) {
            setUserModel(userRoleData && userRoleData.profilePicPath ? userRoleData : userLoginObject.data)
        }
        if (!userRoleInfo && userLoginObject) {
            setUserModel(userLoginObject.data)
        }
        if (homePageData) {
            if (homePageData.landingFooterDetail) {
                setFooter(homePageData.landingFooterDetail[0])
            }

        } else {
            // dispatcher(actionsApi.getHomePageDetail())

        }
    }, [homePageData])

    const authMenu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <a href="/myaccount">My Account</a>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <div onClick={showRoleModal} >Assign Role</div>
                    ),
                },
                {
                    key: '3',
                    label: (
                        <div onClick={showUserModal} >Change Password</div>
                    ),
                },
                {
                    key: '4',
                    label: (
                        <div onClick={handleLogout}>Log Out</div>
                    ),
                },
            ]}
        />
    );

    const [current, setCurrent] = useState('');
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);

    const onClick = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };

    const openDrawer = () => {
        setDrawerIsOpen(true);
    }

    const closeDrawer = () => {
        setDrawerIsOpen(false);
    }



    const onFinish = (value) => {
        setIsupdatingPassword(true)
        console.log('value:', value);
        value.userId = userLoginObject.data._id
        interceptor.axiosPost("POST", "/users/changePassword", value, token).then(res => {
            if (res.data.status == "ok") {
                OpenNotification("", "Update Successfully")
                setisModalVisible(false)
            }
            if (res.status == "error") {
                OpenNotification("", res.message)
            }

            setIsupdatingPassword(false)

        }).catch(error => {
            setIsupdatingPassword(false)
            console.log(error)
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const onFinishAssingRole = (value) => {
        setIsupdatingRole(true)
        console.log('value:', value);
        let model = {
            userRole: value.userRole,
            userId: userLoginObject.data._id
        }

        interceptor.axiosPut("PUT", "/users/addUserRole/", model, token).then(res => {
            if (res.data.status == "ok") {
                OpenNotification("", "Update Successfully")
                setRoleModalVisible(false)
                let defaultReole = userLoginObject.data.defaultRole;
                const filteredResult = userLoginObject && userLoginObject.data.userRolesInfo && userLoginObject.data.userRolesInfo.find((e) => e.role == defaultReole);
                form.resetFields();
                let passingObje = {
                    userRoledId: filteredResult._id ? filteredResult._id : "",
                    token: token,
                    tab: 'about',
                    readOnly: false,
                }
                dispatcher(actionsApi.getRolesDetails(passingObje))

            }
            if (res.status == "error") {
                OpenNotification("", res.message)
            }

            setIsupdatingRole(false)

        }).catch(error => {
            setIsupdatingRole(false)
            console.log(error)
        })
    };

    const onFinishFailedAssingRole = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };



    return (
        <Layout>
            <div className='fs-topbar'>
                <div className='fs-container'>
                    <div className='fs-topbar-content'>
                        <div className='fs-topbar-start'>
                            <ul className="fs-topbar-contact">
                                <li>
                                    <a href={footer ? footer.email : ''}>
                                        <EmailIcon />
                                        <span>{footer ? footer.email : ''}</span>
                                    </a>
                                </li>
                                <li>
                                    <a href={footer ? footer.phoneNumber : ''}>
                                        <PhoneIcon />
                                        <span>{footer ? footer.phoneNumber : ''}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='fs-topbar-end'>
                            <ul className="fs-social-media">
                                <li>
                                    <a href={`${footer ? footer.facebookLink : ''}`} target="_blank">
                                        <Facebook size={12} />
                                    </a>
                                </li>
                                <li>
                                    <a href={`${footer ? footer.twitterLink : ''}`} target="_blank">
                                        <Twitter size={12} />
                                    </a>
                                </li>
                                <li>
                                    <a href={`${footer ? footer.youtubeLink : ''}`} target="_blank">
                                        <Youtube size={12} />
                                    </a>
                                </li>
                                <li>
                                    <a href={`${footer ? footer.linkedInLink : ''}`} target="_blank">
                                        <Linkedin size={12} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Header className='fs-header'>
                <div className='fs-container'>
                    <div className='fs-hamburger' onClick={openDrawer}>
                        <HamBurger size={20} />
                    </div>
                    <div className='fs-logo'>
                        <a href={`${REACT_APP_BASE_URL_App}`}>
                            <Image
                                preview={false}
                                src={Logo}
                                alt="Fitstar" />
                        </a>
                    </div>
                    <Menu
                        onClick={onClick}
                        selectedKeys={[current]}
                        mode="vertical"
                        items={mainMenu}
                    />
                    {token ?
                        <>                    <Dropdown overlay={authMenu} placement="bottom" arrow>
                            <Button className='fs-auth-menu'>
                                <Avatar
                                    src={
                                        <Image preview={false} src={userModel && userModel.profilePicPath ? userModel.profilePicPath : User} />
                                    }
                                />

                                <span className='fs-auth-name'>
                                    {userModel && userModel.username ? userModel.username :
                                        null}
                                </span>

                                <ChevronDown size={12} />
                            </Button>

                        </Dropdown>
                            <Modal
                                title="Change Password"
                                open={isModalVisible}
                                width="600px"
                                onCancel={handleUserCancel}
                                centered
                                closeIcon={<X size={22} />}
                            >
                                <Form
                                    name="changePassword"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    form={form}
                                    className='edit-header-form'
                                >
                                    <Row gutter={20}>

                                        <Col xs={24}>
                                            <Form.Item
                                                name="oldPassword"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input Old Password',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder='Please input Old Password' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24}>
                                            <Form.Item
                                                name="newPassword"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input New Password',
                                                    },
                                                ]}
                                            >
                                                <Input.Password placeholder='Please input New Password' onBlur={checkPassword} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24}>
                                            <Form.Item
                                                name="confirmPassword"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input Confirm Password',
                                                    },
                                                ]}
                                            >
                                                <Input.Password placeholder='Please input Confirm Password' onBlur={checkPassword} />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Button type="primary" htmlType="submit" loading={isUpdatingPassword}>Submit</Button>
                                </Form>
                            </Modal>

                            <Modal
                                title="Assign New Role"
                                open={roleModalVisible}
                                width="600px"
                                onCancel={handleRoleCancel}
                                centered
                                closeIcon={<X size={22} />}
                            >
                                <Form
                                    name="assignRole"
                                    onFinish={onFinishAssingRole}
                                    onFinishFailed={onFinishFailedAssingRole}
                                    autoComplete="off"
                                    form={form}
                                    className='edit-header-form'
                                >
                                    <Row gutter={20}>
                                        <Col xs={24}>
                                            <Form.Item
                                                name="userRole"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Select a role',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    placeholder="Select a Role"
                                                    showArrow
                                                >
                                                    {roleArray.map((item) => (
                                                        <Select.Option key={item.key} value={item.key}>
                                                            {item.center}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Button type="primary" htmlType="submit" loading={isUpdatingRole}>Submit</Button>
                                </Form>
                            </Modal>
                        </>

                        : <>
                            <ul class="fs-auth-nav">
                                <li>
                                    <a href={`${REACT_APP_BASE_URL_App}/signin`}>Log in</a>
                                </li>
                                <li>
                                    <a href={`${REACT_APP_BASE_URL_App}/signup`} className='ant-btn ant-btn-primary'>sign up</a>
                                </li>
                            </ul>
                        </>
                    }
                </div>
            </Header>
            <div className={`fs-side-drawer ${drawerIsOpen ? 'fs-drawer-open' : ''}`}>
                <div className='fs-close-menu' onClick={closeDrawer}>
                    <X size={25} />
                </div>
                <Menu
                    selectedKeys={[current]}
                    mode="vertical"
                    items={mainMenu}
                />

            </div>
            {drawerIsOpen && <div className='fs-drawer-overlay'></div>}
            <Content>
                <Outlet />
                {/* <BlogPage></BlogPage> */}
            </Content>
            <Footer className='fs-footer'>
                <div className='fs-footer-top'>
                    <div className='fs-container'>
                        <Row gutter={[30]}>
                            <Col xs={24} sm={24} md={12} lg={14}>
                                <div className='fs-footer-start'>
                                    <div className='fs-logo'>
                                        <a href={`${REACT_APP_BASE_URL_App}`}>
                                            <Image
                                                preview={false}
                                                src={Logo}
                                                alt="Fitstar" />
                                        </a>
                                    </div>
                                    <ul className='fs-my-account-contact-list fs-mt-30'>
                                        <li>
                                            <PhoneIcon />
                                            <span>{footer ? footer.phoneNumber : ''}</span>
                                        </li>
                                        <li>
                                            <EmailIcon />
                                            <span>{footer ? footer.email : ''}</span>
                                        </li>
                                    </ul>
                                    <ul className="fs-social-media fs-mt-30">
                                        <li>
                                            <a href={`${footer ? footer.facebookLink : ''}`} target="_blank">
                                                <Facebook size={16} />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${footer ? footer.twitterLink : ''}`} target="_blank">
                                                <Twitter size={16} />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${footer ? footer.youtubeLink : ''}`} target="_blank">
                                                <Youtube size={16} />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={`${footer ? footer.linkedInLink : ''}`} target="_blank">
                                                <Linkedin size={16} />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={5} lg={5}>
                                <Title level={4}>Quick Links</Title>
                                <ul className='fs-footer-links'>
                                    <li>
                                        <a href={`${REACT_APP_BASE_URL_App}`}>Home</a>
                                    </li>
                                    <li>
                                        <a href={`${REACT_APP_BASE_URL_App}/about-us`}>About Us</a>
                                    </li>
                                    <li>
                                        <a href={`${REACT_APP_BASE_URL_App}/fitness-directory`}>Fitness Directory</a>
                                    </li>
                                    <li>
                                        <a href="/">Blog</a>
                                    </li>
                                    <li>
                                        <a href={`${REACT_APP_BASE_URL_App}/contact-us`}>Contact Us</a>
                                    </li>
                                </ul>
                            </Col>
                            <Col xs={12} sm={12} md={5} lg={5}>
                                <Title level={4}>Other links</Title>
                                <ul className='fs-footer-links'>
                                    <li>
                                        <a href={`${REACT_APP_BASE_URL_App}/terms-conditions`}>Terms & Conditions</a>
                                    </li>
                                    <li>
                                        <a href={`${REACT_APP_BASE_URL_App}/privacy-policy`}>Privacy Policy</a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='fs-footer-copyright'>{footer ? footer.copyRight : ''}</div>
            </Footer>

        </Layout>


    );
};

export default AppLayout;
