// Libraries
import React from 'react';
import { Typography, Upload, Button, Row, Col, Form, Input, Select, TimePicker, InputNumber } from 'antd';

// Component

// Styles
import './style.css';

// Assets

const { Option } = Select;
const { Title } = Typography;

const EditAds = () => {

    const onFinish = (values) => {
        console.log('Success:', values);
        sendData(values)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onTimeChange = (time, timeString) => {
        console.log(time, timeString);
    };
    const sendData = async (values) => {

        let object = {
            "sessionName": values.sessionName,
            "activities": values.activities,
            // "userRole": "Fitness Center",
            "intensityLevel": values.intensityLevel,
            "time": values.time,
            "location": values.location,
            "spotsAvailability": values.spotsAvailability,
            "price": values.price,
            "description": values.description,
        }

        const formData = new FormData();

        for (const [key, value] of Object.entries(object)) {
            formData.append(key, value);
            console.log(key,value)
        }


        // const response = await fetch(url, {
        //     method: "POST",
        //     body: formData,
        // });

        // ...
    };

    return (
        <div className='fs-my-account fs-gallery'>
            <div className='fs-about-head'>
                <Title level={2}>Edit Ad</Title>
            </div>
            <div className='fs-ads fs-mt-30'>
                <Form
                    name="addAds"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout='vertical'
                >
                    <Row gutter={25}>
                        <Col xs={24} sm={8}>
                            <Form.Item
                                name="sessionName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Session Name',
                                    },
                                ]}
                            >
                                <Input placeholder='Session Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Form.Item
                                name="activities"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select activities',
                                    },
                                ]}
                            >
                                <Select
                                    onChange={handleChange}
                                    placeholder="Select activities"
                                >
                                    <Option value="jack">Jack</Option>
                                    <Option value="lucy">Lucy</Option>
                                    <Option value="Yiminghe">yiminghe</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Form.Item
                                name="intensityLevel"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Intensity Level',
                                    },
                                ]}
                            >
                                <Select
                                    onChange={handleChange}
                                    placeholder="Select Intensity Level"
                                >
                                    <Option value="jack">Jack</Option>
                                    <Option value="lucy">Lucy</Option>
                                    <Option value="Yiminghe">yiminghe</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={8}>
                            <Form.Item
                                name="intensityLevel"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Intensity Level',
                                    },
                                ]}
                            >
                                <Select
                                    onChange={handleChange}
                                    placeholder="Select Intensity Level"
                                >
                                    <Option value="jack">Jack</Option>
                                    <Option value="lucy">Lucy</Option>
                                    <Option value="Yiminghe">yiminghe</Option>
                                </Select>
                            </Form.Item>
                        </Col> */}
                        <Col xs={24} sm={8}>
                            <Form.Item
                                name="time"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Time',
                                    },
                                ]}
                            >
                                <TimePicker style={{ width: "100%" }} use12Hours onChange={onTimeChange} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Form.Item
                                name="location"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Input Location',
                                    },
                                ]}
                            >
                                <Input placeholder='Please Input Location' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Form.Item
                                name="price"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Input Price',
                                    },
                                ]}
                            >
                                <InputNumber addonBefore="$" placeholder='Please Input Price' style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8}>
                            <Form.Item
                                name="spotsAvailability"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Input Spots Availability',
                                    },
                                ]}
                            >
                                <Input placeholder='Please Input Spots Availability' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={25}>
                        <Col xs={24} sm={16}>
                            <div className='fs-upload-gallery'>
                                <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture"
                                    maxCount={1}
                                >
                                    <Button className="ant-btn ant-btn-primary">Upload Ad Image</Button>
                                </Upload>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={25}>
                        <Col xs={24}>
                            <Form.Item
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Input Description',
                                    },
                                ]}
                            >
                                <Input.TextArea placeholder='Please Input Description' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='fs-btn-group'>
                        <Button type="primary" htmlType="submit" className='fs-mr-15 fs-black'>Save</Button>
                        <Button type="primary" htmlType="submit">Cancel</Button>
                    </div>
                </Form>
            </div>

        </div>
    );
}

export default EditAds;