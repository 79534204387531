// Libraries
import React, { useState, useEffect } from 'react';
import { Select, Typography, Button, Form, Input, Radio, Rate, Upload, InputNumber } from 'antd';
import { Link } from 'react-router-dom';
import { Edit3 } from 'react-feather';

// Component

// Styles
import './style.css';
import { useForm } from 'rc-field-form';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import BusinessType from './BusinessType';
import BusinesProfessional from './BusinessProfessional';
import BusinessProfile from './BusinessProfile';
import { DietTypeArray, fitnessCenterArray, fitenessCertificatcate, languageArray, CertificationsList, SpecialtiesArray } from '../../../../data/fitnessCenters';

const { Option } = Select;
const { Title } = Typography;

const OPTIONS = ['Apples', 'Nails', 'Bananas', 'Helicopters'];
const FitnessProfessional = () => {
    const { userTabData } = useShareSelector(state => state.userTabDetail);
    const [selectedItems, setSelectedItems] = useState(userTabData && Object.keys(userTabData.fitnessProfessionalAbout).length != 0 ? userTabData.fitnessProfessionalAbout.qualification.certificationsOrTrainings : null);
    console.log(selectedItems)
    return (
        <>
            <BusinesProfessional />
            <Title level={2} className="fs-mt-30">QUALIFICATIONS</Title>
            <ul className='fs-about-list'>
                <li>
                    <div className='fs-about-title'>Degrees/Diplomas</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="degreesDiplomas"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Degrees/Diplomas',
                                    },
                                ]}
                            >
                                <Input placeholder='Please input degrees/diplomas' />
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Professions</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="professions"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input professions',
                                    },
                                ]}
                            >
                                <Input placeholder='Please input professions' />
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Experience</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="experience"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input experience',
                                    },
                                ]}
                            >
                                <InputNumber min={0} defaultValue={0} addonAfter="Years" style={{ width: "100%" }} />
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Certifications/Trainings</div>
                    <div className='fs-about-end'>

                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="certificateArray"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select a certificate',
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select a certificate"
                                    showArrow
                                    allowClear
                                    value={selectedItems}
                                    onChange={setSelectedItems}
                                >
                                    {CertificationsList.map((item, key) => (
                                        <Select.Option key={key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        {((selectedItems && selectedItems.includes("Other"))) && <div className=' fs-fs-myaccount-sec'>
                            <Form.Item
                                name="certifications"
                                rules={[
                                    {
                                        required: ((selectedItems && selectedItems.includes("Other")) ? true : false),
                                        message: 'Please input certificate',
                                    },
                                ]}
                            >
                                <Input placeholder='Please input certificate if not in List' />
                            </Form.Item>
                        </div>}
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Specialties</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="specialties"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select a specialties',
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select a specialties"
                                    showArrow
                                    allowClear
                                    value={selectedItems}
                                    onChange={setSelectedItems}
                                >
                                    {SpecialtiesArray.map((item) => (
                                        <Select.Option key={item.key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Languages</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="languages"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select a languages',
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select a languages"
                                    showArrow
                                    allowClear
                                    value={selectedItems}
                                    onChange={setSelectedItems}
                                >
                                    {languageArray.map((item) => (
                                        <Select.Option key={item.key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Training Methods and Styles</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="trainingMethods"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Training methods and styles',
                                    },
                                ]}
                            >
                                <Input.TextArea placeholder='Please input training methods and styles' />
                            </Form.Item>
                        </div>
                    </div>
                </li>
            </ul>
            <BusinessProfile />
        </>
    );
}


export default FitnessProfessional;
