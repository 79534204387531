// Libraries
import React, { useState, useEffect } from 'react';
import { Typography, Image } from 'antd';
import Slider from "react-slick";
import { ArrowLeft, ArrowRight } from 'react-feather';
import { Link } from 'react-router-dom';
import domainurl from '../../constants/domainurl';
// Component

// Styles
import './style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Assets
import WeightIcon from '../../assets/images/weight.svg';

const { Title } = Typography;

const Collaborators = (props) => {

    const [list, setlist] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log("getCollaborators........");
        getCollaborators();
    }, []);


    const getCollaborators = () => {
        setIsLoading(true)

        fetch(domainurl + '/collaborators/getAllActive', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status == "ok") {
                    console.log("collaborators data", res.result);
                    setlist(res.result);
                }
                setIsLoading(false)

            })
            .catch((error) => {
                setIsLoading(false)

            });
    }

    var settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: list.length == 1 ? 1 : list.length == 2 ? 2 : list.length == 3 ? 3 : 3,
        slidesToScroll: list.length == 1 ? 1 : list.length == 2 ? 2 : list.length == 3 ? 3 : 3,
        prevArrow: <div className='fs-slick-arrows'><ArrowLeft size={18} /></div>,
        nextArrow: <div className='fs-slick-arrows'><ArrowRight size={18} /></div>,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    return (
        <div className='fs-ambassadors fs-section fs-slick-arrows'>
            <div className='fs-container'>
                <div className='fs-block-header'>
                    <Image preview={false} src={WeightIcon} alt="" />
                    <Title level={1}>{props.title}</Title>
                </div>

                <Slider {...settings} className="fs-slick-slider">

                    {
                        list.map((s, i) => {
                            return (

                                <div className='fs-ambassadors-slider' key={i} id={i}>
                                    <Image preview={false} src={s.path ? s.path : props.image} alt="" />
                                    <Title level={4}>{s.title}</Title>
                                    {(s.facebookUrl || s.twitterUrl || s.instagramUrl) ?
                                        <ul className="fs-social-links" key={i}>
                                            {s.facebookUrl ?
                                                <li>
                                                    <a href={s.facebookUrl} target="_blank">
                                                        {props.fb}
                                                    </a>
                                                </li>
                                                : null}

                                            {s.twitterUrl ?
                                                <li>
                                                    <a href={s.twitterUrl} target="_blank">
                                                        {props.tw}
                                                    </a>
                                                </li>

                                                : null}

                                            {s.instagramUrl ?
                                                <li>
                                                    <a href={s.instagramUrl} target="_blank">
                                                        {props.li}
                                                    </a>
                                                </li>
                                                : null}
                                            {s.youtubeUrl ?
                                                <li>
                                                    <a href={s.youtubeUrl} target="_blank">
                                                        {props.yo}
                                                    </a>
                                                </li>
                                                : null}
                                        </ul>
                                        : null}
                                </div>

                            )
                        }
                        )
                    }
                    
                </Slider>
            </div>
        </div>
    )
}

export default Collaborators;