// Libraries
import React, { useState, useEffect } from 'react';
import { Select, Typography, Button, Form, Input, InputNumber } from 'antd';
import { Trash2 } from 'react-feather';

// Component
import {
    FitnessCenterTypeArray,
    SpecialtiesArray,
    LanguagesArray,
    OurFitnessProsArray
} from '../../../../data/fitnessCenters';

// Styles
import './style.css';

// Assets

const { Option } = Select;
const { Title } = Typography;

const EditAboutCenter = () => {

    const onFinish = (values) => {
        console.log('Success:updated', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='fs-my-account fs-about'>
            <Form
                name="editAboutModel"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className='fs-about-head'>
                    <Title level={2}>Key Info</Title>
                    <div className='fs-circle-btn'>
                        <div to="#" className='fs-round-btn-large ant-btn ant-btn-primary'>
                            <Trash2 size={24} />
                        </div>
                    </div>
                </div>
                <ul className='fs-about-list'>
                    <li>
                        <div className='fs-about-title'>Name</div>
                        <div className='fs-about-end'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="name"
                                >
                                    <Input placeholder='Please input your name' />
                                </Form.Item>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Profile Url</div>
                        <div className='fs-about-end'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="profileUrl"
                                >
                                    <Input placeholder='Please input your profile URL' />
                                </Form.Item>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Open Since</div>
                        <div className='fs-about-end'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="profileUrl"
                                >
                                    <Input placeholder='Please input your profile URL' />
                                </Form.Item>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Fitness Center Type</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="fitnessCenterType"
                            >
                                <Select
                                    placeholder="Select fitness center type"
                                    allowClear
                                    showSearch
                                >
                                    {FitnessCenterTypeArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Languages</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="languages"
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select languages"
                                    showArrow
                                    allowClear
                                    showSearch
                                >
                                    {LanguagesArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Specialties</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="modelingInterests"
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select activities"
                                    showArrow
                                    allowClear
                                    showSearch
                                >
                                    {SpecialtiesArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Hours Of Operation</div>
                        <div className='fs-about-end'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="hoursOfOperation"
                                >
                                    <InputNumber defaultValue={0} placeholder='Please input hours of operation' />
                                </Form.Item>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Membership Plans</div>
                        <div className='fs-about-end'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="membershipPlans"
                                >
                                    <Input placeholder='Please input membership plans' />
                                </Form.Item>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Accomplishments</div>
                        <div className='fs-about-end'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="accomplishments"
                                >
                                    <Input placeholder='Please input accomplishments' />
                                </Form.Item>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Our Fitness Pros</div>
                        <div className='fs-about-end'>
                            <Form.Item
                                name="ourFitnessPros"
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select our fitness pros"
                                    showArrow
                                    allowClear
                                    showSearch
                                >
                                    {OurFitnessProsArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Our Story/About Us</div>
                        <div className='fs-about-end'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="ourStory"
                                >
                                    <Input.TextArea placeholder='Please input our story' />
                                </Form.Item>
                            </div>
                        </div>
                    </li>
                </ul>
                <Button type="primary" htmlType="submit" className="fs-mt-30">update</Button>
            </Form>
        </div>
    );
}

export default EditAboutCenter;