// Libraries
import React, { useState, useEffect } from 'react';
import { Select, Typography, Button, Form, Input, Radio, Rate, Upload, InputNumber } from 'antd';
import { Link } from 'react-router-dom';
import { Edit3 } from 'react-feather';
// Styles
import './style.css';
import { useShareDispatch } from '../../../../shared';
import { clientPreference, fitnessCenterArray } from '../../../../data/fitnessCenters';

// Assets

const { Option } = Select;
const { Title } = Typography;

const OPTIONS = ['Apples', 'Nails', 'Bananas', 'Helicopters'];
const LANGUAGE_OPTION = [{ "language": 'English', "key": "FC" }, { "language": 'Franch', "key": "FM" }, { "language": 'Dutch', "key": "FP" }];
const SPECIALITIES_OPTION = [{ "language": 'Arobic', "key": "Arobic" }, { "language": 'Body building', "key": "Body building" }, { "language": 'Dutch', "key": "Dutch" }];
const FITNESS_PROS_OPTION = [{ "language": 'New Work', "key": "New Work" }, { "language": 'Rome', "key": "Rome" }, { "language": 'London', "key": "London" }];

const BusinesProfessional = (props) => {
    const [selectedRole, setSelectedLanguage] = useState([]);
    const [selectedSpecialities, setSelectedSpecialited] = useState([]);
    const [fitnessProsOption, setFintessProsOption] = useState([]);
    const handleRole = (value) => {
        console.log(`selected ${value}`);
        setSelectedLanguage(value)
    };
    const handleSpecialities = (value) => {
        console.log(`selected ${value}`);
        setSelectedSpecialited(value)
    };
    const handleProsOption = (value) => {
        console.log(`selected ${value}`);
        setFintessProsOption(value)
    };

    return (
        <>
            <ul className='fs-about-list'>
                <li>
                    <div className='fs-about-title'>Business Name</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="businessName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your business name',
                                    },
                                ]}
                            >
                                <Input placeholder='Please input your business name' />
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Client Preference</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="clientPreference"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select a role',
                                    },
                                ]}
                            >
                                <Select
                                    // mode="multiple"
                                    placeholder="Select a client Preference option"
                                    showArrow
                                    value={selectedRole}

                                    onChange={handleRole}
                                >
                                    {clientPreference.map((item) => (
                                        <Select.Option key={item.key} value={item.key}>
                                            {item.center}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Availability for in-home training</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="availabilityin_home_training"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select a traning method',
                                    },
                                ]}
                            >

                                <Select
                                    placeholder="Select availability for in-home training"
                                    allowClear
                                >
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                </Select>
                                {/* <Select
                                    mode="multiple"
                                    placeholder="Select a traning method."
                                    showArrow
                                    value={selectedRole}
                                    onChange={handleRole}
                                >
                                    {LANGUAGE_OPTION.map((item) => (
                                        <Select.Option key={item.key} value={item.key}>
                                            {item.language}
                                        </Select.Option>
                                    ))}
                                </Select> */}
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Availability for on-line-live training</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="Availabilityforonline_training"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select a training option',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select availability for on-line-live training"
                                    allowClear
                                >
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                </Select>
                                {/* <Select
                                    mode="multiple"
                                    placeholder="Select a training option"
                                    showArrow
                                    value={selectedSpecialities}
                                    onChange={handleSpecialities}
                                >
                                    {SPECIALITIES_OPTION.map((item) => (
                                        <Select.Option key={item.key} value={item.key}>
                                            {item.language}
                                        </Select.Option>
                                    ))}
                                </Select> */}
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Availability For Corporate Fitness</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="availabilityFoCorporateFitness"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select  Availability For Corporate Fitness',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select Availability For Corporate Fitness"
                                    allowClear
                                >
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Training Rates</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="trainingRates"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input training rates',
                                    },
                                    // {
                                    //     message: 'Pleas input number only',
                                    //     validator: (_, value) => {
                                    //         if (/^[0-9]+$/.test(value)) {
                                    //             return Promise.resolve();
                                    //         } else {
                                    //             return Promise.reject('Pleas input number only');
                                    //         }
                                    //     }
                                    // }
                                ]}
                            >
                                <InputNumber min={0} defaultValue={0} />
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Note About Training Rates</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="noteaboutTrainingRates"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input note about training rates',
                                    },
                                ]}
                            >

                                <Input placeholder='Please input note about training rates' />
                            </Form.Item>
                        </div>
                    </div>
                </li>
            </ul>
        </>
    );
}


export default BusinesProfessional;
