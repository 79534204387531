// Libraries
import React, { useEffect, useState } from 'react';
import { Avatar, Col, Image, Spin, Typography } from 'antd';

// Component

// Styles
import './style.css';

// Assets
import blogImg from '../../assets/images/hero.jpg';
import { useParams } from 'react-router-dom';
import { useShareDispatch, useShareSelector } from '../../shared';
import useToken from '../../hooks/useToken';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';
import moment from 'moment';
import ReactPlayer from 'react-player';
import { Youtube } from 'react-feather';

const { Title } = Typography;

const BlogDetail = () => {
    const { id } = useParams();
    const { token } = useToken();
    const dispatcher = useShareDispatch();
    const [model, setModel] = useState(null);
    const [item, setItem] = useState(null);
    const { isLoadingblogs, blogs } = useShareSelector(state => state.blogs)
    useEffect(() => {
        debugger
        if (id && !blogs) {
            dispatcher(actionsApi.getAllActiveBlogs(token))

        }
        if (blogs) {
            let items = blogs.find(x => x._id == id)
          
            try {
                setItem(JSON.parse(items.blogDetail));
            } catch (error) {
                // If parsing as JSON fails, treat it as plain text
                model = items.blogDetail ; // Or handle it as needed
            }
            console.log(items)
            setModel(items)
        }

    }, [id, blogs])
    return (
        <div className='fs-blog-detail'>
            <div className='fs-blog-detail-img'>
                {isLoadingblogs ? <div className='fs-spin'><Spin /></div>
                    : <>
                        {model && model.pathFeaturedImage && <img preview={false} style={{ "width": model.pathSecoundFeaturedImage ? "50%" : "100%" }} src={model && model.pathFeaturedImage} alt="" />}
                        {model && model.pathSecoundFeaturedImage && <img preview={false} style={{ "width": model.pathFeaturedImage ? "50%" : "100%" }} src={model && model.pathSecoundFeaturedImage} alt="" />}

                    </>}
            </div>

            <div className='fs-container'>
                <div class="" style={{ display: 'flex' }}>
                    <Avatar size={50}
                        src={
                            <Image className='blog-image-author' src={model && model.pathAuthorImage} />
                        }
                    />

                    <Title className='fs-auth-name' level={1}>{model && model.title}</Title>
                </div>

                {model && <ul className='fs-blog-list'>
                    <li>{model.authorName}</li>
                    <li className='fs-list-dot'></li>
                    <li>{moment(model.createdAt).format("DD MMM YYYY")}</li>
                </ul>}
                <Col xs={24} md={24}>

                    <div className='fs-video fs-card'>
                        <div className='player-wrapper'>
                            {model && model.videoLink &&
                                <ReactPlayer
                                    className='react-player'
                                    width='100%'
                                    height='100%'
                                    playIcon={<Youtube size={70} />}
                                    controls
                                    url={model.videoLink}
                                    light={false}
                                />
                            }
                        </div>
                        {model && <div class="ant-tag-blog"> <span  >{model.category}</span></div>}

                    </div>
                </Col>

                {model && <p dangerouslySetInnerHTML={{ __html: JSON.parse(model.blogDetail) }}></p>}
            </div>
        </div>
    );
}

export default BlogDetail;