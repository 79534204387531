// Libraries
import React, { useEffect, useState } from 'react';
import { Typography, Image, Tabs, Row, Col, Spin } from 'antd';
import { Link } from 'react-router-dom';

// Component
import { BlogPost } from '../../../../components';
import { BlogPostData } from '../../../../data/BlogPost';

// Styles
import './style.css';

// Assets
import HeroImg from '../../../../assets/images/hero.jpg';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import useToken from '../../../../hooks/useToken';

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const BlogPosts = (props) => {
    console.log(props)
    const { blog } = props
    const [model, setModel] = useState(null);
    const { isLoadingblogs, blogs } = useShareSelector(state => state.blogs)
    useEffect(() => {
        if (blog) {
            setModel(blog)

        }
    }, [props])

    const onChange = (key) => {
        console.log(key);
        if (key == 'all') {
            setModel(blog)
        }
        else {
            let items = blog.filter(x => x.category.toLowerCase().indexOf(key.toLowerCase()) >= 0)
            setModel(items)
        }

    };

    return (
        <div className='fs-blog-posts'>
            <div className='fs-container'>
                <div className='fs-tabs'>
                    <Tabs defaultActiveKey="all" onChange={onChange}>
                        <TabPane tab="all" key="all">

                        </TabPane>
                        <TabPane tab="nutrition" key="nutrition">
                        </TabPane>
                        <TabPane tab="news" key="news">
                        </TabPane>
                        <TabPane tab="recipes" key="recipes">
                        </TabPane>
                        <TabPane tab="workouts" key="workouts">
                        </TabPane>
                        <TabPane tab="reviews" key="reviews">
                        </TabPane>
                        <TabPane tab="podcasts" key="podcasts">
                        </TabPane>
                        <TabPane tab="music" key="music">
                        </TabPane>
                    </Tabs>

                    <Row gutter={[30]}>
                        {isLoadingblogs ? <div className='fs-spin'><Spin /></div> :
                            <>
                                {(model || []).map((item, index) => (
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} key={index}>
                                        <BlogPost item={item} />
                                    </Col>
                                ))}
                            </>

                        }
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default BlogPosts;