// Libraries
import React, { useState, useEffect } from 'react';
import { Select, Typography, Button, Form, Input, Radio, Rate, Upload, DatePicker, TimePicker, Space } from 'antd';
import { Link } from 'react-router-dom';
import { Edit3 } from 'react-feather';
// Styles
import './style.css';
import { useShareDispatch } from '../../../../shared';
import { fitnessCenterArray, FitnessCenterTypeArray, fitnessCenterTypeArray, languageArray, LanguagesArray, MemberShipArray, SpecialtiesArray, weekDayWithTime } from '../../../../data/fitnessCenters';
import moment from 'moment';
import interceptor from '../../../../shared/interceptor';
import useToken from '../../../../hooks/useToken';

// Assets

const { Option } = Select;
const { Title } = Typography;

const OPTIONS = ['Apples', 'Nails', 'Bananas', 'Helicopters'];
const LANGUAGE_OPTION = [{ "language": 'English', "key": "FC" }, { "language": 'Franch', "key": "FM" }, { "language": 'Dutch', "key": "FP" }];
const SPECIALITIES_OPTION = [{ "language": 'Arobic', "key": "Arobic" }, { "language": 'Body Building', "key": "Body Building" }, { "language": 'Dutch', "key": "Dutch" }];
const FITNESS_PROS_OPTION = [{ "language": 'New Work', "key": "New Work" }, { "language": 'Rome', "key": "Rome" }, { "language": 'London', "key": "London" }, { "language": 'Istanbul', "key": "Istanbul" }, { "language": 'Paris', "key": "Paris" }];


const BusinessType = (props) => {
    const [selectedRole, setSelectedLanguage] = useState([]);
    const [selectedSpecialities, setSelectedSpecialited] = useState([]);
    const [fitnessProsOption, setFintessProsOption] = useState([]);
    const [fitnessPros, setFintessPros] = useState([]);
    const handleRole = (value) => {
        debugger
        console.log(`selected ${value}`);
        setSelectedLanguage(value)
    };
    const handleSpecialities = (value) => {
        console.log(`selected ${value}`);
        setSelectedSpecialited(value)
    };
    const handleProsOption = (value) => {
        console.log(`selected ${value}`);
        setFintessProsOption(value)
    };

    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };
    const { token } = useToken();

    useEffect(() => {
        interceptor.axiosGet("GET", "/users/getFPUserListFC", token).then(res => {
            console.log("resposne", res.data.result)
            if (res.data.status == "ok") {
                if (res.data.result) {
                    setFintessPros(res.data.result)
                }
            }
        }).catch(error => {
        })
    }, [])
    return (
        <ul className='fs-about-list'>
            <li>
                <div className='fs-about-title'>Business Name</div>
                <div className='fs-about-end'>
                    <div className='fs-myaccount-about-text'>
                        <Form.Item
                            name="businessName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your business name',
                                },
                            ]}
                        >
                            <Input placeholder='Please input your business name' />
                        </Form.Item>
                    </div>
                </div>
            </li>
            <li>
                <div className='fs-about-title'>Profile Url</div>
                <div className='fs-about-end'>
                    <div className='fs-myaccount-about-text'>
                        <Form.Item
                            name="profileurl"
                            rules={[
                                {
                                    message: 'Input your profile image url',
                                },
                            ]}
                        >
                            <Input placeholder='Input your profile image url' />
                        </Form.Item>
                    </div>
                </div>
            </li>
            <li>
                <div className='fs-about-title'>Open Since</div>
                <div className='fs-about-end'>
                    <div className='fs-myaccount-about-text'>
                        <Form.Item
                            name="OpenSince"
                            rules={[
                                {
                                    required: true,
                                    message: 'Open Since is required',
                                },
                            ]}
                        >

                            <DatePicker picker="year" style={{ width: "100%" }}
                                onChange={onChange}
                                defaultValue={moment('2000', 'YYYY')}
                                disabledDate={(current) => {
                                    let customDate = moment().format("YYYY") + 1;
                                    return current && current > moment(customDate, "YYYY");

                                }}
                            />
                        </Form.Item>
                    </div>
                </div>
            </li>

            <li>
                <div className='fs-about-title'>Fitness Center Type</div>
                <div className='fs-about-end'>
                    <div className='fs-myaccount-about-text'>
                        <Form.Item
                            name="fitnessCenterType"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select a role',
                                },
                            ]}
                        >
                            <Select
                                // mode="multiple"
                                placeholder="Select a Role"
                                showArrow
                                value={selectedRole}
                                onChange={handleRole}
                            >
                                {FitnessCenterTypeArray.map((item) => (
                                    <Select.Option key={item.key} value={item.name}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                    </div>
                </div>
            </li>
            <li>
                <div className='fs-about-title'>Languages</div>
                <div className='fs-about-end'>
                    <div className='fs-myaccount-about-text'>
                        <Form.Item
                            name="language"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Languages',
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Select a Languages"
                                showArrow
                                value={selectedRole}
                                onChange={handleRole}
                            >
                                {LanguagesArray.map((item) => (
                                    <Select.Option key={item.key} value={item.name}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
            </li>
            <li>
                <div className='fs-about-title'>Specialties</div>
                <div className='fs-about-end'>
                    <div className='fs-myaccount-about-text'>
                        <Form.Item
                            name="sepecialties"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Specialties',
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Select a Specialties"
                                showArrow
                                value={selectedSpecialities}
                                onChange={handleSpecialities}
                            >
                                {SpecialtiesArray.map((item) => (
                                    <Select.Option key={item.name} value={item.name}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
            </li>


            <li>
                <div className='fs-about-title'>Availability for in-home training</div>
                <div className='fs-about-end'>
                    <div className='fs-myaccount-about-text'>
                        <Form.Item
                            name="inHomeTraining"
                            rules={[
                                {
                                    message: 'Select availability for in-home training',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select availability for in-home training"
                                allowClear
                            >
                                <Option value="yes">Yes</Option>
                                <Option value="no">NO</Option>
                            </Select>
                        </Form.Item>
                    </div>
                </div>
            </li>
            {/* <li>
                    <div className='fs-about-title'>Availability for on-line-live training</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="availabilityOnline"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select availability for on-line-live training',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select availability for on-line-live training"
                                    allowClear
                                >
                                    <Option value="jack">Jack</Option>
                                    <Option value="lucy">Lucy</Option>
                                    <Option value="Yiminghe">yiminghe</Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li> */}
            <li>
                <div className='fs-about-title'>Hours Of Operation</div>
                <div className='fs-about-end'>



                    {
                        weekDayWithTime.map((item, index) => {
                            return (
                                <Form.Item name="timeRanged">
                                    <div className='fs-myaccount-about-text'>

                                        <ul className='weekdays'>
                                            <li>
                                                <Form.Item key={index}
                                                    name={item.day}
                                                >
                                                    {item.day}
                                                </Form.Item>
                                            </li>
                                            <li>
                                                <Form.Item key={item.day}
                                                    name={item.day}
                                                >
                                                    <TimePicker.RangePicker
                                                        use12Hours
                                                        // value={[moment('2:00:00 AM','h:mm:ss A'), moment('10:00:00 PM','h:mm:ss A')]}
                                                        format="h:mm:ss A"
                                                        onChange={(value, dateString) => {
                                                            console.log('Time', value, dateString);
                                                            return (dateString);
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    // defaultValue={moment('13:30:56', 'HH:mm:ss')}
                                                    />
                                                </Form.Item>
                                            </li>

                                        </ul>
                                        <Input.Group>   </Input.Group>


                                    </div>
                                </Form.Item>
                            )
                        })
                    }

                </div>
            </li>
            <li>
                <div className='fs-about-title'>Membership Plans</div>
                <div className='fs-about-end'>
                    <div className='fs-myaccount-about-text'>
                        <Form.Item
                            name="membershipplans"
                            rules={[
                                {

                                    message: 'Please input Membership Plans',

                                },
                            ]}
                        >
                            <Select
                                placeholder='Please input your Membership Plans'
                                showArrow
                                allowClear
                            >
                                {MemberShipArray.map((item) => (
                                    <Select.Option key={item.key} value={item.name}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
            </li>
            <li>
                <div className='fs-about-title'>Accomplishments</div>
                <div className='fs-about-end'>
                    <div className='fs-myaccount-about-text'>
                        <Form.Item
                            name="accomplishments"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Accomplishments',
                                },
                            ]}
                        >
                            <Input placeholder='Please input your Accomplishments' />
                        </Form.Item>
                    </div>
                </div>
            </li>
            <li>
                <div className='fs-about-title'>Our Fitness Pros</div>
                <div className='fs-about-end'>
                    <div className='fs-myaccount-about-text'>
                        <Form.Item
                            name="ourFitnessPros"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Our Fitness Pros',
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Select a Our Fitness Pros"
                                showArrow
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {fitnessPros && fitnessPros.map((item) => (
                                    <Select.Option key={item._id} value={item._id}>
                                        {item.username}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
            </li>
            <li>
                <div className='fs-about-title'>Our Story</div>
                <div className='fs-about-end'>
                    <div className='fs-myaccount-about-text'>
                        <Form.Item
                            name="trainingNote"
                            rules={[
                                {

                                    message: 'Please input note about training rates',
                                },
                            ]}
                        >
                            <Input.TextArea placeholder='Please input Story/about Fitness Center' />
                        </Form.Item>
                    </div>
                </div>
            </li>
        </ul>
    );
}


export default BusinessType;
