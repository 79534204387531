// Libraries
import React, { useEffect, useState } from 'react';
import useToken from '../../hooks/useToken';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';
import { useShareDispatch, useShareSelector } from '../../shared';

// Component
import { BlogSlider, BlogPosts } from './components';

// Styles
import './style.css';

// Assets

const BlogPage = () => {


    const { token } = useToken();
    const dispatcher = useShareDispatch();
    const [model, setModel] = useState(null);
    const { isLoadingblogs, blogs } = useShareSelector(state => state.blogs)
    useEffect(() => {
        if (blogs) {
            console.log(blogs)
            setModel(blogs)

        } else {
            dispatcher(actionsApi.getAllActiveBlogs(token))

        }
    }, [blogs])



    return (
        <div className='fs-blog-page'>
            <BlogSlider blog={blogs} />
            <BlogPosts blog={blogs} />
        </div>
    );
}

export default BlogPage;