import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import OpenNotification from "../../../components/Notifications";
import useToken from "../../../hooks/useToken";
import interceptor from "../../../shared/interceptor";

export const getAllActiveAmbassadors = createAsyncThunk(
    "ambassadors/getAllActive",
    async (object, { dispatch, getState }) => {
        dispatch(gettingResponse())
        interceptor.axiosGet("get", "/ambassadors/getAllActive", object, {}).then(res => {
            if (res.status == 200) {
                dispatch(gettingResponseSuccess(res.data.result))

            } else {
                dispatch(gettingResponseSuccess({}))
                OpenNotification("topRight", res.message)
            }
        }).catch(error => {
            dispatch(gettingResponseError(error))
            OpenNotification("topRight", error)

        })

    },
);


const ambassadorsSlice = createSlice({
    name: "ambassadors",
    initialState: {

        ambassadors: null,
        status: "idle",
        isLoadingAmbassadors: false,
        error: null,
        isError: false
    },
    reducers: {
        gettingResponseError: (state, action) => {
            state.status = "error";
            state.ambassadors = null;
            state.error = action.payload;
            state.isLoadingPage = false;
            state.isError = true;

        },
        gettingResponseSuccess: (state, action) => {

            state.status = "success";
            state.ambassadors = action.payload;
            state.isLoadingAmbassadors = false;
            state.isError = false;
        },
        gettingResponse: (state, action) => {
            state.status = "loading";
            state.isLoadingAmbassadors = true;
            state.ambassadors = null;
            state.isError = false;
        },
    },

});


// Action creators are generated for each case reducer function
export const { gettingResponse, gettingResponseError, gettingResponseSuccess } =
    ambassadorsSlice.actions;

export default ambassadorsSlice.reducer;
