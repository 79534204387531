// Libraries
import React from 'react';
import { Form, Button, Upload, Typography } from 'antd';

// Component

// Styles
import './style.css';

// Assets

const { Title } = Typography;

const EditPortfolio = () => {
    return (
        <div className='fs-my-account fs-portfolio'>
            <div className='fs-about-head'>
                <Title level={2}>Edit Success stories</Title>
            </div>
            <div className='fs-portfolio-card fs-add-portfolio fs-mt-30'>
                <ul className='fs-about-list'>
                    <li>
                        <div className='fs-about-title'>Select Before Image</div>
                        <div className='fs-about-end'>
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture"
                                maxCount={1}
                            >
                                <Button className='ant-btn ant-btn-primary'>Brows Image</Button>
                            </Upload>
                        </div>
                    </li>
                    <li>
                        <div className='fs-about-title'>Select After Image</div>
                        <div className='fs-about-end'>
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture"
                                maxCount={1}
                            >
                                <Button className='ant-btn ant-btn-primary'>Brows Image</Button>
                            </Upload>
                        </div>
                    </li>
                </ul>
                <div className='fs-btn-group fs-mt-20'>
                    <Button type="primary" htmlType="submit" className='fs-mr-15 fs-black'>Add</Button>
                    <Button type="primary" htmlType="submit">Cancel</Button>
                </div>
            </div>
        </div>
    );
}

export default EditPortfolio;