// Libraries
import React from 'react';
import { Typography, Image } from 'antd';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

// Component

// Styles
import './style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Assets
import HeroImg from '../../../../assets/images/hero.jpg';

const { Title, Paragraph } = Typography;

const BlogSlider = (props) => {
    const { blog } = props
    var settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };




    return (
        <div className='fs-blog-slider'>
            <Slider {...settings}>
                {(blog || []).map((item, index) => (
                    index < 6 && <div className='fs-blog-slide' key={index}>
                        <Link to="#">
                            <Image preview={false} src={item.pathFeaturedImage} alt="" />
                            <Title level={1}>{item.title}</Title>
                        </Link>
                    </div>

                ))}

            </Slider>
        </div>
    );
}

export default BlogSlider;