// Libraries
import React, { useEffect, useState } from 'react';
import { Image, Select, Typography, Rate } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { Edit3 } from 'react-feather';

// Component
import { useMyAccountContext } from '../../../../context/MyAccount/MyAccount';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import { AboutCenter, AboutModel } from '..';
import useToken from '../../../../hooks/useToken';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';

// Styles
import './style.css';

// Assets

const { Option } = Select;
const { Title, Paragraph } = Typography;

const About = (props) => {
    const { userRoleInfo } = useParams()
    const { userTabData } = useShareSelector(state => state.userTabDetail);
    const [fitnessCenter, setfitnessCenter] = useState(null)
    const [fitnessModal, setfitnessModal] = useState(false)
    const [fitnessProfessional, setfitnessProfessional] = useState(false)
    const { fitnessCenterType, setFitnessCenterType } = useMyAccountContext()

    const { token, userLoginObject } = useToken();


    const editValue = (id) => {
        props.onEditAbout(id)
    }

    useEffect(() => {
        if (userTabData) {
            if (userTabData.fitnessCenterAbout) {
                setFitnessCenterType('FC')
                onLoadData('FC')

            }
            if (userTabData.fitnessModalAbout) {
                setFitnessCenterType('FM')
                onLoadData('FM')
            }
            if (userTabData.fitnessProfessionalAbout) {
                setFitnessCenterType('FP')
                onLoadData('FP')
            }
        }
    }, [userTabData])


    const onLoadData = (centerType) => {
        switch (centerType) {
            case "FC":
                let fitnessCenterAbout = userTabData && userTabData.fitnessCenterAbout;
                console.log(fitnessCenterAbout)
                let timeJSon = null
                try {
                    timeJSon = JSON.parse(fitnessCenterAbout.timeRange)
                }
                catch {
                    timeJSon = [
                        {
                            day: 'Monday',
                            value: fitnessCenterAbout.timeRange,
                        },
                        {
                            day: 'Tuesday',
                            value: fitnessCenterAbout.timeRange,
                        },
                        {
                            day: 'Wednesday',
                            value: fitnessCenterAbout.timeRange,
                        },
                        {
                            day: 'Thursday',
                            value: fitnessCenterAbout.timeRange,
                        },
                        {
                            day: 'Friday',
                            value: fitnessCenterAbout.timeRange,
                        },
                        {
                            day: 'Saturday',
                            value: fitnessCenterAbout.timeRange,
                        },
                        {
                            day: 'Sunday',
                            value: fitnessCenterAbout.timeRange,
                        },
                    ];
                    debugger

                }
                if (fitnessCenterAbout && Object.keys(fitnessCenterAbout).length != 0) {
                    setfitnessCenter({
                        businessName: `${fitnessCenterAbout.businessName ? fitnessCenterAbout.businessName : ""}`,
                        profileurl: `${fitnessCenterAbout.profileUrl ? fitnessCenterAbout.profileUrl : ""}`,
                        OpenSince: `${fitnessCenterAbout.openSince ? fitnessCenterAbout.openSince : ""}`,
                        accomplishments: `${fitnessCenterAbout.accomplishments ? fitnessCenterAbout.accomplishments : ""}`,
                        inHomeTraining: `${fitnessCenterAbout.inHomeTraining ? fitnessCenterAbout.inHomeTraining : ""}`,
                        fitnessCenterType: `${fitnessCenterAbout.fitnessCenterType ? fitnessCenterAbout.fitnessCenterType : ""}`,
                        timeRange: timeJSon ? timeJSon : [],
                        language: `${fitnessCenterAbout.languages ? fitnessCenterAbout.languages : ""}`,
                        membershipplans: `${fitnessCenterAbout.membershipPlans ? fitnessCenterAbout.membershipPlans : ""}`,
                        ourFitnessPros: `${fitnessCenterAbout.ourFitnessPros ? fitnessCenterAbout.ourFitnessPros : ""}`,
                        fitnessPros: fitnessCenterAbout.fitnessPros ? fitnessCenterAbout.fitnessPros : [],

                        sepecialties: `${fitnessCenterAbout.specialties ? fitnessCenterAbout.specialties : ""}`,
                        trainingNote: `${fitnessCenterAbout.OurStoryAboutUs ? fitnessCenterAbout.OurStoryAboutUs : ""}`,

                    })
                    console.log(fitnessCenter)
                }

                break;
            case "FM":
                let fitnessModalAbout = userTabData && userTabData.fitnessModalAbout;
                if (fitnessModalAbout && Object.keys(fitnessModalAbout).length != 0) {
                    {
                        setfitnessModal({
                            profileUrl: `${fitnessModalAbout.profileUrl ? fitnessModalAbout.profileUrl : ""}`,
                            name: `${fitnessModalAbout.name ? fitnessModalAbout.name : ""}`,
                            gender: `${fitnessModalAbout.gender ? fitnessModalAbout.gender : ""}`,
                            age: `${fitnessModalAbout.ageYears ? fitnessModalAbout.ageYears : ""}`,
                            height: `${fitnessModalAbout.height ? fitnessModalAbout.height : ""}`,
                            selectHeight: `${fitnessModalAbout.heightMeasurement ? fitnessModalAbout.heightMeasurement : ""}`,
                            weight: `${fitnessModalAbout.weight ? fitnessModalAbout.weight : ""}`,
                            selectWeight: `${fitnessModalAbout.weightMeasurement ? fitnessModalAbout.weightMeasurement : ""}`,
                            bodyType: `${fitnessModalAbout.bodyType ? fitnessModalAbout.bodyType : ""}`,
                            ethnicity: `${fitnessModalAbout.ethnicity ? fitnessModalAbout.ethnicity : ""}`,
                            skinTone: `${fitnessModalAbout.skinTone ? fitnessModalAbout.skinTone : ""}`,
                            eyeColor: `${fitnessModalAbout.eyeColor ? fitnessModalAbout.eyeColor : ""}`,
                            hairLength: `${fitnessModalAbout.hairLength ? fitnessModalAbout.hairLength : ""}`,
                            tattoos: `${fitnessModalAbout.tattoos ? fitnessModalAbout.tattoos : ""}`,
                            pricing: `${fitnessModalAbout.pricing ? fitnessModalAbout.pricing : ""}`,
                            experience: `${fitnessModalAbout.experience ? fitnessModalAbout.experience : ""}`,
                            languages: `${fitnessModalAbout.languages ? fitnessModalAbout.languages : ""}`,
                            workingWithMedia: `${fitnessModalAbout && fitnessModalAbout.workingWithMedia ? 'Yes' : 'No'}`,
                            ModelingInterests: `${fitnessModalAbout.modelingInterests ? fitnessModalAbout.modelingInterests : ""}`,
                            Compensation: `${fitnessModalAbout.compensation ? fitnessModalAbout.compensation : ""}`,
                            notesAboutComensation: `${fitnessModalAbout.notesAboutCompensation ? fitnessModalAbout.notesAboutCompensation : ""}`,
                            activities: `${fitnessModalAbout.activities ? fitnessModalAbout.activities : ""}`,
                            StoryAboutme: `${fitnessModalAbout.myStoryOrAboutMe ? fitnessModalAbout.myStoryOrAboutMe : ""}`,
                            sponsors: fitnessModalAbout.sponsors

                        })
                    }
                }


                break;
            case "FP":
                const fitnessProfessionalAbout = userTabData && userTabData.fitnessProfessionalAbout;
                console.log(fitnessProfessionalAbout)
                if (fitnessProfessionalAbout && Object.keys(fitnessProfessionalAbout).length != 0) {
                    setfitnessProfessional(
                        {
                            // business: {
                            reviews: userTabData ? userTabData.reviews : "",
                            availabilityin_home_training: `${fitnessProfessionalAbout.business && fitnessProfessionalAbout.business.availabilityForInHomeTaining ? fitnessProfessionalAbout.business.availabilityForInHomeTaining : ""}`,
                            Availabilityforonline_training: `${fitnessProfessionalAbout.business && fitnessProfessionalAbout.business.availabilityForOnlineliveTaining ? fitnessProfessionalAbout.business.availabilityForOnlineliveTaining : ""}`,
                            businessName: `${fitnessProfessionalAbout.business && fitnessProfessionalAbout.business.businessName ? fitnessProfessionalAbout.business.businessName : ""}`,
                            clientPreference: `${fitnessProfessionalAbout.business && fitnessProfessionalAbout.business.clientPreference ? fitnessProfessionalAbout.business.clientPreference : ""}`,
                            noteaboutTrainingRates: `${fitnessProfessionalAbout.business && fitnessProfessionalAbout.business.noteAboutTainingRates ? fitnessProfessionalAbout.business.noteAboutTainingRates : ""}`,
                            trainingRates: `${fitnessProfessionalAbout.business && fitnessProfessionalAbout.business.trainingRates ? fitnessProfessionalAbout.business.trainingRates : ""}`,
                            availabilityFoCorporateFitness: `${fitnessProfessionalAbout.business && fitnessProfessionalAbout.business.availabilityFoCorporateFitness ? fitnessProfessionalAbout.business.availabilityFoCorporateFitness : ""}`,

                            // },
                            // personal: {
                            activities: `${fitnessProfessionalAbout.personal && fitnessProfessionalAbout.personal.activities ? fitnessProfessionalAbout.personal.activities : ""}`,
                            age: `${fitnessProfessionalAbout.personal && fitnessProfessionalAbout.personal.ageYears ? fitnessProfessionalAbout.personal.ageYears : ""}`,
                            BodyType: `${fitnessProfessionalAbout.personal && fitnessProfessionalAbout.personal.bodyType ? fitnessProfessionalAbout.personal.bodyType : ""}`,
                            DietType: `${fitnessProfessionalAbout.personal && fitnessProfessionalAbout.personal.dietType ? fitnessProfessionalAbout.personal.dietType : ""}`,
                            gender: `${fitnessProfessionalAbout.personal && fitnessProfessionalAbout.personal.gender ? fitnessProfessionalAbout.personal.gender : ""}`,
                            height: `${fitnessProfessionalAbout.personal && fitnessProfessionalAbout.personal.height ? fitnessProfessionalAbout.personal.height : ""}`,
                            selectHeight: `${fitnessProfessionalAbout.personal && fitnessProfessionalAbout.personal.heightMeasurement ? fitnessProfessionalAbout.personal.heightMeasurement : ""}`,
                            StoryAboutme: `${fitnessProfessionalAbout.personal && fitnessProfessionalAbout.personal.myStoryOrAboutMe ? fitnessProfessionalAbout.personal.myStoryOrAboutMe : ""}`,
                            persionalname: `${fitnessProfessionalAbout.personal && fitnessProfessionalAbout.personal.name ? fitnessProfessionalAbout.personal.name : ""}`,
                            profileUrl: `${fitnessProfessionalAbout.personal && fitnessProfessionalAbout.personal.profileUrl ? fitnessProfessionalAbout.personal.profileUrl : ""}`,
                            weight: `${fitnessProfessionalAbout.personal && fitnessProfessionalAbout.personal.weight ? fitnessProfessionalAbout.personal.weight : ""}`,
                            selectWeight: `${fitnessProfessionalAbout.personal && fitnessProfessionalAbout.personal.weightMeasurement ? fitnessProfessionalAbout.personal.weightMeasurement : ""}`,
                            fitnessCenters: `${fitnessProfessionalAbout.personal.fitnessCenters ? fitnessProfessionalAbout.personal.fitnessCenters : ""}`,
                            fitnessCenterNames: `${fitnessProfessionalAbout.personal.fitnessCenterNames ? fitnessProfessionalAbout.personal.fitnessCenterNames : ""}`,

                            // },
                            // qualification: {
                            certificateArray: `${fitnessProfessionalAbout.qualification && fitnessProfessionalAbout.qualification.certificationsOrTrainings ? fitnessProfessionalAbout.qualification.certificationsOrTrainings : ""}` + " " + `${fitnessProfessionalAbout.qualification && fitnessProfessionalAbout.qualification.certifications ? fitnessProfessionalAbout.qualification.certifications : ""}`,
                            degreesDiplomas: `${fitnessProfessionalAbout.qualification && fitnessProfessionalAbout.qualification && fitnessProfessionalAbout.qualification.degrees ? fitnessProfessionalAbout.qualification.degrees : ""}`,
                            experience: `${fitnessProfessionalAbout.qualification && fitnessProfessionalAbout.qualification.experience ? fitnessProfessionalAbout.qualification.experience : ""}`,
                            languages: `${fitnessProfessionalAbout.qualification && fitnessProfessionalAbout.qualification.languages ? fitnessProfessionalAbout.qualification.languages : ""}`,
                            professions: `${fitnessProfessionalAbout.qualification && fitnessProfessionalAbout.qualification.professions ? fitnessProfessionalAbout.qualification.professions : ""}`,
                            specialties: `${fitnessProfessionalAbout.qualification && fitnessProfessionalAbout.qualification.specialties ? fitnessProfessionalAbout.qualification.specialties : ""}`,
                            trainingMethods: `${fitnessProfessionalAbout.qualification && fitnessProfessionalAbout.qualification.trainingMethodsAndStyles ? fitnessProfessionalAbout.qualification.trainingMethodsAndStyles : ""}`,
                            sponsors: fitnessProfessionalAbout.sponsors
                            //}


                        }

                    )
                }
                break;


        }
    }

    return (
        <>
            <div className='fs-my-account fs-about'>
                <div className='fs-about-head'>
                    <Title level={2}> {fitnessCenterType == 'FM' ? 'Key Info' : 'BUSINESS'}</Title>
                    {!userRoleInfo && <div className='fs-circle-btn'>
                        <Link to="#" onClick={() =>
                            editValue(true)} className='fs-round-btn-large ant-btn ant-btn-primary'>
                            <Edit3 size={24} />
                        </Link>
                    </div>}
                </div>
                {fitnessProfessional && fitnessCenterType == 'FP'
                    && <div>
                        <ul className='fs-about-list'>
                            <li>
                                <div className='fs-about-title'>Business Name</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.businessName} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Client Preference</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.clientPreference} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Availability for in-home training</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.availabilityin_home_training} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Availability for on-line-live training</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.Availabilityforonline_training} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Availability For Corporate Fitness</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.availabilityFoCorporateFitness} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Training Rates</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.trainingRates} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Note About Training Rates</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.noteaboutTrainingRates} </div>}
                            </li>
                        </ul>
                        <Title level={2} className="fs-mt-30">QUALIFICATIONS</Title>
                        <ul className='fs-about-list'>
                            <li>
                                <div className='fs-about-title'>Degrees/Diplomas</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.degreesDiplomas} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Professions</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.professions} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Experience</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.experience} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Certifications/Trainings</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.certificateArray.replace("Other", "")} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Specialties</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.specialties} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Languages</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.languages} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Training Methods and Styles</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.trainingMethods} </div>}
                            </li>
                        </ul>
                        <Title level={2} className="fs-mt-30">Personal</Title>
                        <ul className='fs-about-list'>
                            <li>
                                <div className='fs-about-title'>Name</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.persionalname} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Profile Url</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.profileUrl} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Gender</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.gender == 1 ? 'Male' : fitnessProfessional.gender == 2 ? 'Female' : 'Other'} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Age</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.age} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Height</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.height} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Weight</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.weight} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Diet Type</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.DietType} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Body Type</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.BodyType} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Activities</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.activities} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>Fitness Center</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.fitnessCenterNames} </div>}
                            </li>
                            <li>
                                <div className='fs-about-title'>My Story/About me</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.StoryAboutme} </div>}
                            </li>
                            {/* <li className='fs-align-items-top'>
                                <div className='fs-about-title'>Product Reviews</div>
                                <div className='fs-about-end'>
                                    <div className='fs-helper-block fs-helper-zero-padd'>
                                        <Title level={5}>Product Name</Title>
                                        <Paragraph>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</Paragraph>
                                        <div className='fs-fs-myaccount-sec'>
                                            <Rate disabled />
                                        </div>
                                    </div>
                                    <div className='fs-helper-block fs-helper-zero-padd'>
                                        <Title level={5}>Product Name</Title>
                                        <Paragraph>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</Paragraph>
                                        <div className='fs-fs-myaccount-sec'>
                                            <Rate disabled />
                                        </div>
                                    </div>
                                </div>
                            </li> */}
                            <li className='fs-align-items-top'>
                                <div className='fs-about-title'>Sponsor Images</div>
                                <div className='fs-about-end'>
                                    <div className='fs-helper-block fs-helper-zero-padd'>
                                        <div className='fs-sponsor-image'>
                                            <Image.PreviewGroup>


                                                {fitnessProfessional && fitnessProfessional.sponsors && fitnessProfessional.sponsors.map((item) => (
                                                    <Image
                                                        src={item.path}
                                                    />
                                                ))}

                                            </Image.PreviewGroup>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {/* <li>
                                <div className='fs-about-title'>Product Reviews</div>
                                {fitnessProfessional && <div className='fs-about-end'>{fitnessProfessional.reviews} </div>}
                            </li> */}
                            {/* <li>
                                <div className='fs-about-title'>Sponsor Images</div>
                                {userTabData && <div className='fs-about-end'>{userTabData.name} </div>}
                            </li> */}
                        </ul>
                    </div>
                }
                {fitnessCenter && fitnessCenterType == 'FC'
                    && <AboutCenter model={fitnessCenter} />}
                {fitnessModal && fitnessCenterType == 'FM'
                    && <AboutModel model={fitnessModal} />}
            </div>
        </>
    );
}

export default About;