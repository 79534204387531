// Libraries
import React, { useState, useEffect } from 'react';
import { Select, Typography, Button, Form, Input, Radio, Rate, Upload, InputNumber } from 'antd';
import { Link } from 'react-router-dom';
import { Edit3 } from 'react-feather';
// Styles
import './style.css';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import { fitnessCenterArray, skinTone, eyeColor, hairyLength, languageArray, modelingInterestArray, compensationArray, piercingsArray, experienceArray, activitiesArray, BodyTypeArray, HeightArray, CompensationArray, ActivitiesArray, SkinToneArray, EyeColorArray, HairLengthArray, TattoosArray, LanguagesArray, EthnicityArray, EthnicityModelArray, HeightProArray } from '../../../../data/fitnessCenters';
import { useMyAccountContext } from '../../../../context/MyAccount/MyAccount';
import useToken from '../../../../hooks/useToken';

// Assets

const { Option } = Select;
const { Title } = Typography;

const OPTIONS = ['Apples', 'Nails', 'Bananas', 'Helicopters'];
const FitnessModal = () => {
    const [selectedItems, setSelectedItems] = useState([]);
    const { fitnessCenterType } = useMyAccountContext()
    const [modelingInterest, setModelingIntrest] = useState([])
    const [fileList, setFileList] = useState(null);
    const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));
    const [value, setValue] = useState(1);
    const { token } = useToken()
    const { userTabData } = useShareSelector(state => state.userTabDetail);
    const [form] = Form.useForm();
    
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    const handlingModelInterest = (value) => {
        setModelingIntrest(value)
    }

    useEffect(() => {
        debugger
        let fitnessModelAbout = userTabData && userTabData.fitnessModalAbout;
        if (fitnessModelAbout && Object.keys(fitnessModelAbout).length != 0) {
            if (fitnessModelAbout.sponsors && fitnessModelAbout.sponsors.length > 0) {
                let obj = []
                fitnessModelAbout.sponsors.forEach(item => {
                    obj.push({
                        uid: item._id,
                        url: item.path,
                    })
                });
                setFileList(obj)
                form.setFieldsValue({
                    sponsors: obj,
                });
            }
        }
    }, [])
    return (
        <>
            {/* <Title level={2} className="fs-mt-30"> {fitnessCenterType == 'FM' ? 'Key Info' : 'Personal'}</Title> */}
            <ul className='fs-about-list'>
                <li>
                    <div className='fs-about-title'>Name</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input name',
                                    },
                                ]}
                            >
                                <Input placeholder='Name' />
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Profile Url</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="profileUrl"
                                rules={[
                                    {
                                        message: 'Please input profile url',
                                    },
                                ]}
                            >
                                <Input placeholder='Profile Url' />
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Gender</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="gender"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select gender',
                                    },
                                ]}
                            >
                                <Radio.Group onChange={onChange} value={value}>
                                    <Radio value={1}>Male</Radio>
                                    <Radio value={2}>Female</Radio>
                                    <Radio value={3}>Other</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Age</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="age"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input age',
                                    },
                                ]}
                            >
                                <InputNumber min={1} max={100} defaultValue={1} addonAfter="Years" style={{ width: "100%" }} />
                            </Form.Item>
                        </div>
                        {/* <div className='fs-fs-myaccount-sec'>
                            <Form.Item
                                name="ageType"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please Select age',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select age"
                                    defaultValue="Years" disabled
                                >
                                    <Option selected value="Years">Years</Option>

                                </Select>
                            </Form.Item>
                        </div> */}
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Height</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="height"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input height',
                                    },
                                ]}
                            >
                                <InputNumber min={1} defaultValue={1} />
                            </Form.Item>
                        </div>
                        <div className='fs-fs-myaccount-sec'>
                            <Form.Item
                                name="selectHeight"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select height',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Height"
                                    allowClear
                                >
                                    {HeightProArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Weight</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="weight"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input weight',
                                    },
                                ]}
                            >
                                <InputNumber min={1} defaultValue={1} />
                            </Form.Item>
                        </div>
                        <div className='fs-fs-myaccount-sec'>
                            <Form.Item
                                name="selectWeight"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select weight',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Weight"
                                    allowClear
                                >
                                    <Option value="Pounds">Pounds</Option>
                                    <Option value="kilo">Kilo</Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Body Type</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="bodyType"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select body type',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Body Type"
                                    allowClear
                                >
                                    {BodyTypeArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Ethnicity</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="ethnicity"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Ethnicity',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Ethnicity"
                                    allowClear
                                >
                                    {EthnicityModelArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Skin Tone</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="skinTone"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Skin Tone',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Skin Tone"
                                    allowClear
                                >
                                    {SkinToneArray.map((item) => (
                                        <Select.Option key={item.key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}


                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Eye Color</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="eyeColor"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Eye Color',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Eye Color"
                                    allowClear
                                >
                                    {eyeColor.map((item) => (
                                        <Select.Option key={item.key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Hair Length</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="hairLength"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Hair Length',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Hair Length"
                                    allowClear
                                >
                                    {HairLengthArray.map((item) => (
                                        <Select.Option key={item.key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Tattoos</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="tattoos"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Tattoos',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Tattoos"
                                    allowClear
                                >
                                    {TattoosArray.map((item) => (
                                        <Select.Option key={item.key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Piercings</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="pricing"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Piercings',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Piercings"
                                    allowClear
                                >
                                    {piercingsArray.map((item) => (
                                        <Select.Option key={item.key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Experience</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="experience"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Experience',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Experience"
                                    allowClear
                                >
                                    {experienceArray.map((item) => (
                                        <Select.Option key={item.key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Languages</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="languages"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Languages',
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Languages"
                                    allowClear

                                >
                                    {LanguagesArray.map((item) => (
                                        <Select.Option key={item.key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Working With Media</div>
                    <div className='fs-about-end'>
                        <Form.Item

                            name="workingWithMedia"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Modeling Interests</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="ModelingInterests"

                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Modeling Interests',
                                    },
                                ]}

                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Modeling Interests"
                                    value={modelingInterest}
                                    onChange={setModelingIntrest}
                                    allowClear
                                    showArrow

                                >
                                    {modelingInterestArray.map((item) => (
                                        <Select.Option key={item.key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Compensation</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="Compensation"

                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select Compensation',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Compensation"
                                    allowClear
                                >
                                    {CompensationArray.map((item) => (
                                        <Select.Option key={item.key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Notes About Compensation</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="notesAboutComensation"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Notes About Compensation',
                                    },
                                ]}
                            >
                                <Input.TextArea placeholder='Notes About Compensation' />
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>Activities</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="activities"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Select activities',
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="activities"
                                    showArrow
                                    allowClear
                                    value={selectedItems}
                                    onChange={setSelectedItems}
                                >
                                    {ActivitiesArray.map((item) => (
                                        <Select.Option key={item.key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li>
                    <div className='fs-about-title'>My Story/About me</div>
                    <div className='fs-about-end'>
                        <div className='fs-myaccount-about-text'>
                            <Form.Item
                                name="StoryAboutme"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input story/about me',
                                    },
                                ]}
                            >
                                <Input.TextArea placeholder='About Me' />
                            </Form.Item>
                        </div>
                    </div>
                </li>
                <li className='fs-align-items-top'>
                    <div className='fs-about-title'>Sponsor Images</div>
                    <div className='fs-about-end'>
                        {/* <div className='fs-helper-block'>
                            <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="sponsorName"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please input sponsor name',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Please input sponsor name' />
                                </Form.Item>
                            </div>
                            <div className='fs-fs-myaccount-sec'>
                                <Upload
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture"
                                    maxCount={1}
                                >
                                    <Button className='fs-upload'>Choose File</Button>
                                </Upload>
                            </div>
                            <div className='fs-circle-btn'>
                                <div to="#" className='fs-round-btn-large ant-btn ant-btn-primary'>
                                    <Trash2 size={18} />
                                </div>
                            </div>
                        </div> */}
                        <div className='fs-helper-block'>
                            {/* <div className='fs-myaccount-about-text'>
                                <Form.Item
                                    name="sponsorName"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please input sponsor name',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Please input sponsor name' />
                                </Form.Item>
                            </div> */}
                            <div className='fs-fs-myaccount-sec'>
                                <Form.Item

                                    name="sponsors"
                                >
                                    <Upload
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture"
                                        multiple={true}
                                        fileList={fileList}
                                        onChange={({ fileList: newFileList }) => {
                                            setFileList(newFileList);
                                        }}
                                        beforeUpload={(file) => {
                                            return false
                                        }}
                                    >
                                        <Button className='fs-upload'>Choose File</Button>
                                    </Upload>
                                </Form.Item>
                            </div>
                            {/* <div className='fs-circle-btn'>
                                <div to="#" className='fs-round-btn-large ant-btn ant-btn-primary'>
                                    <Trash2 size={18} />
                                </div>
                            </div> */}
                        </div>
                        {/* <div className='fs-fs-myaccount-sec'>
                            <Button type="primary" >Add New Sponsor Image</Button>
                        </div> */}
                    </div>
                </li>

                {/* <li>
                                  <div className='fs-about-title'>Product Reviews</div>
                                  <div className='fs-about-end'>
                                      <div className='fs-myaccount-about-text'>
                                          <Form.Item
                                              name="product"
                                              rules={[
                                                  {
                                                      required: true,
                                                      message: 'Please input product',
                                                  },
                                              ]}
                                          >
                                              <Input.TextArea placeholder='Please input product' />
                                          </Form.Item>
                                      </div>
                                      <div className='fs-fs-myaccount-sec'>
                                          <Form.Item
                                              name="reviews"
                                              rules={[
                                                  {
                                                      required: true,
                                                      message: 'Please input reviews',
                                                  },
                                              ]}
                                          >
                                              <Input.TextArea placeholder='Please input reviews' />
                                          </Form.Item>
                                      </div>
                                      <div className='fs-fs-myaccount-sec'>
                                          <Rate allowHalf defaultValue={2.5} />
                                      </div>
                                      <Button type="primary" htmlType="submit" className='fs-mt-15'>Add New Product Review</Button>
                                  </div>
                              </li> */}
                {/* <li>
                                  <div className='fs-about-title'>Sponsor Images</div>
                                  <div className='fs-about-end'>
                                      <div className='fs-myaccount-about-text'>
                                          <Form.Item
                                              name="sponsorName"
                                              rules={[
                                                  {
                                                      required: true,
                                                      message: 'Please input sponsor name',
                                                  },
                                              ]}
                                          >
                                              <Input placeholder='Please input sponsor name' />
                                          </Form.Item>
                                      </div>
                                      <div className='fs-fs-myaccount-sec'>
                                          <Upload
                                              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                              listType="picture"
                                              maxCount={1}
                                          >
                                              <Button className='fs-upload'>Choose File</Button>
                                          </Upload>
                                      </div>
                                      <div className='fs-fs-myaccount-sec'>
                                          <Button type="primary" htmlType="submit">Add New Sponsor Image </Button>
                                      </div>
                                  </div>
                              </li> */}
            </ul>
        </>
    );
}


export default FitnessModal;
