// Libraries
import React, { useEffect, useState } from 'react';
import { Typography, Image, Modal } from 'antd';
import Slider from "react-slick";
import { ArrowLeft, ArrowRight } from 'react-feather';
import { Link } from 'react-router-dom';
import domainurl from '../../constants/domainurl';
// Component

// Styles
import './style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Assets
import WeightIcon from '../../assets/images/weight.svg';
import { useShareDispatch, useShareSelector } from '../../shared';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';

const { Title } = Typography;

const Ambassadors = (props) => {
    const [isLoadingDetail, setIsLoadingDetail] = useState(false);
    const { isLoadingAmbassadors, ambassadors } = useShareSelector(state => state.ambassadorsDetail)
    const dispatcher = useShareDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [model, setModel] = useState(null);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {

        if (ambassadors) {

        } else {
            dispatcher(actionsApi.getAllActiveAmbassadors())

        }
    }, [ambassadors])

    var settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: ambassadors && ambassadors.length == 1 ? 1 : ambassadors && ambassadors.length == 2 ? 2 : ambassadors && ambassadors.length == 3 ? 3 : 3,
        slidesToScroll: ambassadors && ambassadors.length == 1 ? 1 : ambassadors && ambassadors.length == 2 ? 2 : ambassadors && ambassadors.length == 3 ? 3 : 3,
        prevArrow: <div className='fs-slick-arrows'><ArrowLeft size={18} /></div>,
        nextArrow: <div className='fs-slick-arrows'><ArrowRight size={18} /></div>,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    return (
        <div className='fs-ambassadors fs-section fs-slick-arrows'>
            <div className='fs-container' >
                <div className='fs-block-header'>
                    <Image preview={false} src={WeightIcon} alt="" />
                    <Title level={1}>{props.title}</Title>
                </div>

                <Slider {...settings} className="fs-slick-slider">
                    {ambassadors && ambassadors.map(item => {
                        return (
                            <div className='fs-ambassadors-slider ' onClick={() => {
                                setIsModalOpen(true)
                                setModel(item)
                            }}>
                                <span className=''>
                                <Image className='ant-image-hover' preview={false} src={item.path} alt="" />
                                </span>
                                <Title level={4}>{item.name}</Title>
                                <ul className="fs-social-links">
                                    {item.facebookUrl ?
                                        <li>
                                            <a href={item.facebookUrl} target="_blank">
                                                {props.fb}
                                            </a>
                                        </li>
                                        : null}

                                    {item.twitterUrl ?
                                        <li>
                                            <a href={item.twitterUrl} target="_blank">
                                                {props.tw}
                                            </a>
                                        </li>

                                        : null}

                                    {item.instagramUrl ?
                                        <li>
                                            <a href={item.instagramUrl} target="_blank">
                                                {props.li}
                                            </a>
                                        </li>
                                        : null}

                                </ul>
                            </div>
                        )
                    })

                    }

                </Slider>
            </div>

            <Modal title={model ? model.name : ''} open={isModalOpen} onCancel={handleCancel} >
                <div className='fs-ambassadors-slider'>
                    <Image preview={false} src={model ? model.path : ''} alt="" />
                    <Title level={4}>{model ? model.name : ''}</Title>
                    <p level={4}>{model ? model.description : ''}</p>
                    <ul className="fs-social-links" style={{ marginRight: '40px' }}>
                        {model && model.facebookUrl ?
                            <li>
                                <a href={model.facebookUrl} style={{ padding: '8px' }} target="_blank">
                                    {props.fb}
                                </a>
                            </li>
                            : null}

                        {model && model.twitterUrl ?
                            <li>
                                <a href={model.twitterUrl} style={{ padding: '8px' }} target="_blank">
                                    {props.tw}
                                </a>
                            </li>

                            : null}

                        {model && model.instagramUrl ?
                            <li>
                                <a href={model.instagramUrl} style={{ padding: '8px' }} target="_blank">
                                    {props.li}
                                </a>
                            </li>
                            : null}

                    </ul>
                </div>
            </Modal>
        </div>



    )
}

export default Ambassadors;