import React from 'react';

import { Navigate, Outlet } from 'react-router-dom'
import useToken from '../hooks/useToken';

const PublicRoute = () => {
    const { token, setToken } = useToken();

    return token ? <Navigate to="/myaccount" /> : <Outlet />
}

export default PublicRoute;