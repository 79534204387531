// Libraries
import React from 'react';
import { Provider } from 'react-redux';

// Component
import Entry from './Entry';

// Styles
import './App.less';
import { store } from './shared';
import { MyAccount } from './context/MyAccount/MyAccount';

function App() {
  return (
    <Provider store={store}>
      <MyAccount>
        <Entry />
      </MyAccount>

    </Provider>

  );
}

export default App;
