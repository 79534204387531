// Libraries
import React, { useState } from 'react';
import { Typography, Upload, Button } from 'antd';

// Component

// Styles
import './style.css';
import useToken from '../../../../hooks/useToken';
import domainurl from '../../../../constants/domainurl';
import { useMyAccountContext } from '../../../../context/MyAccount/MyAccount';
import OpenNotification from '../../../../components/Notifications';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import { useShareDispatch } from '../../../../shared';

// Assets

const { Title } = Typography;

const AddGallery = (props) => {
    const { updateImage } = props;
    const [fileList, setFileList] = useState()
    const { userRolesInfo } = useMyAccountContext()
    const [isLoader, setIsLoader] = useState(false)

    console.log(props)
    const dispatcher = useShareDispatch();

    const { token } = useToken();
    const uploadButton = (
        <div>

            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const onUpdate = () => {
        props.onUpdateGallery(false)
    }


    // useEffect(() => {
    //     if (props.model._id) {
    //         form.setFieldsValue({
    //             videoLink: `${props.model.url}`,
    //             videoCategory: `${props.model.category}`,

    //         });
    //     }
    //     else {
    //         form.resetFields();
    //     }

    // }, [])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleUpload = (file) => {
        //---------------^^^^^----------------
        // this is equivalent to your "const img = event.target.files[0]"
        // here, antd is giving you an array of files, just like event.target.files
        // but the structure is a bit different that the original file
        // the original file is located at the `originFileObj` key of each of this files
        // so `event.target.files[0]` is actually fileList[0].originFileObj
        console.log('fileList', file);

        // you store them in state, so that you can make a http req with them later
        setFileList(file);
    };

    const uploadImage = (file) => {
        debugger
        if (fileList && fileList.fileList && fileList.fileList.length > 0) {
            setIsLoader(true)
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + token);

            var formdata = new FormData();
            fileList.fileList.forEach(item => {
                formdata.append("image", item.originFileObj);

            });
            // formdata.append("image", fileInput.files[0]);

            console.log(file)

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(domainurl + "/users/addGallery/" + userRolesInfo.roleId, requestOptions)
                .then(res => res.json())
                .then(res => {
                    console.log(res)
                    if (res.status == "ok") {
                        OpenNotification("", "Image successfully added.")
                        props.onUpdateGallery(false)
                        let passingObje = {
                            userRoledId: userRolesInfo.roleId,
                            token: token,
                            tab: 'gallery'
                        }
                        dispatcher(actionsApi.getUserTabDetails(passingObje))
                    }
                    setIsLoader(false)
                }).catch(err => {
                    setIsLoader(false)

                })
        }
    }
    return (
        <div className='fs-my-account fs-gallery'>
            <div className='fs-about-head'>
                <Title level={2}>Update Gallery Images</Title>
            </div>
            <div className='fs-upload-gallery fs-mt-40'>
                <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture"
                    onChange={handleUpload}
                    maxCount={5}
                    multiple
                    beforeUpload={() => false}
                >
                    <Button className="ant-btn ant-btn-primary">Browse Images</Button>
                </Upload>
                {/* <input
                    // filename={file}
                    onChange={(e) => handleUpload(e.target.files[0])}
                    type="file"
                    accept="image/*"
                    //value={props.model.path}
                    id="image-selection-btn"
                ></input> */}
            </div>
            <Button type="primary" loading={isLoader} onClick={() => uploadImage()} className='fs-mt-40 fs-mr-15 fs-black'>save</Button>

            <Button type="primary" onClick={onUpdate} >Cancel</Button>
        </div>
    );
}

export default AddGallery;