// Libraries
import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

// Component
import PublicRoute from './routes/PublicRoute';
import PrivateRoutes from './routes/PrivateRoutes';
import ShareRoute from './routes/shareRoute';

import Signin from './pages/auth/Signin';
import Signup from './pages/auth/Signup';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import { AppLayout } from './components';
import MyAccount from './pages/my-account';
import PublicProfile from './pages/public-profile';

import HomePage from './pages/home';
import AboutPage from './pages/about';
import FitnessDirectoryPage from './pages/fitness-directory';
import ContactPage from './pages/contact';
import BlogPage from './pages/blog';
import BlogDetail from './pages/blog-detail';
import TermsAndCondition from './pages/termAndCondition';

// Styles
import './App.less';
import PrivacyAndPolicy from './pages/privacyAndPolicy';

function Entry() {
    return (
        <Routes>

            <Route path="/" element={<AppLayout />}>
                <Route path="/" slug="Home" element={<BlogPage />} />
                <Route path="blog-detail/:id" element={<BlogDetail />} />
            </Route>
        </Routes>
    );
}

export default Entry;
