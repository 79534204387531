// Libraries
import React, { useEffect, useState } from 'react';
import { Typography, Upload, Button, Row, Col, Form, Input, Select, TimePicker, InputNumber } from 'antd';

// Component

// Styles
import './style.css';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import { useMyAccountContext } from '../../../../context/MyAccount/MyAccount';
import useToken from '../../../../hooks/useToken';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import OpenNotification from '../../../../components/Notifications';
import domainurl from '../../../../constants/domainurl';
import moment from 'moment';
import { ActivitiesArray, CompensationArray, intensityLevel, modelingInterestArray, weekday, weekDayWithTime } from '../../../../data/fitnessCenters';

// Assets

const { Option } = Select;
const { Title } = Typography;

const AddAds = (props) => {
    const dispatcher = useShareDispatch();
    const [isSubmitting, setIsSubmitting] = useState();

    const [fileList, setFileList] = useState()
    const [adModel, setAdModel] = useState(null)
    const { token } = useToken();
    const { userRolesInfo } = useMyAccountContext()
    const { userAds } = useShareSelector(state => state.userTabDetail);
    const [form] = Form.useForm();


    const onFinish = (values) => {
        setIsSubmitting(true)
        console.log('Success:', values);
        values._id = props.model._id ? props.model._id : -1
        values.userRolesInfo = userRolesInfo.roleId
        if (userRolesInfo.roleName != "FM") {
            values.time = JSON.stringify([
                {
                    day: 'Monday',
                    value: values.Monday,
                },
                {
                    day: 'Tuesday',
                    value: values.Tuesday,
                },
                {
                    day: 'Wednesday',
                    value: values.Wednesday,
                },
                {
                    day: 'Thursday',
                    value: values.Thursday,
                },
                {
                    day: 'Friday',
                    value: values.Friday,
                },
                {
                    day: 'Saturday',
                    value: values.Saturday,
                },
                {
                    day: 'Sunday',
                    value: values.Sunday,
                },
            ])
        }



        fetch(domainurl + "/users/addEditAdData/", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` },
            body: JSON.stringify(values)

        })
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.status == "ok") {
                    uploadImage(res)
                }

            }).catch(err => {
                setIsSubmitting(false)
            })
    }

    const uploadImage = (res) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);

        var formdata = new FormData();

        formdata.append("image", fileList[0].originFileObj);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(domainurl + "/users/addEditAdsImage/" + userRolesInfo.roleId + "/" + res.data._id, requestOptions)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.status == "ok") {
                    OpenNotification("", "Image successfully added.")
                    props.onUpdateAds(false)
                    let passingObje = {
                        userRoledId: userRolesInfo.roleId,
                        token: token,
                        tab: 'ads'
                    }
                    dispatcher(actionsApi.getUserTabDetails(passingObje))
                }
                setIsSubmitting(false)
            }).catch(err => {
                setIsSubmitting(false)
            })
    }

    useEffect(() => {
        setAdModel(props.model)
        if (props.model._id) {
            setFileList([{
                url: props.model.path
            }]);
            if (userRolesInfo.roleName == "FM") {
                form.setFieldsValue({
                    title: `${props.model.title}`,
                    activities: `${props.model.activities}`,
                    compensation: `${props.model.compensation}`,
                    location: props.model.location,
                    modelingInterests: props.model.modelingInterests,
                    price: `${props.model.price}`,
                    description: `${props.model.description}`,
                });
            } else {
                form.setFieldsValue({
                    title: `${props.model.title}`,
                    activities: props.model.activities,
                    intensityLevel: props.model.intensityLevel,
                    days: props.model.days,
                    time: moment(props.model.time),
                    location: props.model.location,
                    spotsAvailability: `${props.model.spotsAvailability}`,
                    price: `${props.model.price}`,
                    description: `${props.model.description}`,
                });
            }
        }
        else {
            form.resetFields();
        }

    }, [])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const handleChangeImage = (file) => {
        console.log('fileList', file.fileList);
        setFileList(file.fileList);
    };

    const onUpdate = () => {
        props.onUpdateAds(false)
    }


    const onTimeChange = (time, timeString) => {
        console.log(time, timeString);
    };

    return (
        <div className='fs-my-account fs-gallery'>
            <div className='fs-about-head'>
                <Title level={2}>Add Ad</Title>
            </div>
            <div className='fs-ads fs-mt-30'>
                <Form
                    name="addAds"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout='vertical'
                    form={form}
                >
                    <Row gutter={25}>
                        <Col xs={24} sm={8}>
                            <Form.Item
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message: userRolesInfo && userRolesInfo.roleName != "FM" ? 'Please Input Name' : 'Please Input Title',
                                    },
                                ]}
                            >
                                <Input placeholder={userRolesInfo && userRolesInfo.roleName != "FM" ? 'Session Name' : 'Service Title'} />
                            </Form.Item>
                        </Col>
                        {userRolesInfo && userRolesInfo.roleName != "FM" &&
                            <>
                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="activities"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Select Activity',
                                            },
                                        ]}
                                    >
                                        <Select

                                            showArrow
                                            allowClear
                                            onChange={handleChange}
                                            placeholder="Activity"
                                        >
                                            {ActivitiesArray.map((item) => (
                                                <Select.Option key={item.key} value={item.name}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="intensityLevel"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Intensity Level',
                                            },
                                        ]}
                                    >
                                        <Select
                                            onChange={handleChange}
                                            placeholder="Intensity Level"
                                        >
                                            {intensityLevel.map((item) => (
                                                <Select.Option key={item.key} value={item.key}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {/* <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="days"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Select Days',
                                            },
                                        ]}

                                    >
                                        <Select
                                            mode="multiple"
                                            showArrow
                                            allowClear
                                            onChange={handleChange}
                                            placeholder="Days"
                                        >
                                            {weekday.map((item) => (
                                                <Select.Option key={item.key} value={item.key}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="time"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Select Time',
                                            },
                                        ]}
                                    >
                                        <TimePicker style={{ width: "100%" }} use12Hours onChange={onTimeChange} />
                                    </Form.Item>
                                </Col> */}


                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="spotsAvailability"
                                    >
                                        <Input placeholder='Spots Availability' />
                                    </Form.Item>
                                </Col>
                            </>
                        }
                        <Col xs={24} sm={8}>
                            <Form.Item
                                name="price"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Input Price',
                                    },
                                ]}
                            >
                                <InputNumber placeholder='Price' addonBefore="$" min={0} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>

                        {userRolesInfo && userRolesInfo.roleName == "FM" &&
                            <>
                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="modelingInterests"

                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Select Modeling Interests',
                                            },
                                        ]}

                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="Modeling Interests"
                                            allowClear
                                            showArrow

                                        >
                                            {modelingInterestArray.map((item) => (
                                                <Select.Option key={item.key} value={item.key}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <Form.Item
                                        name="compensation"

                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Select Compensation',
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Compensation"
                                            allowClear
                                        >
                                            {CompensationArray.map((item) => (
                                                <Select.Option key={item.key} value={item.key}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}

                                        </Select>
                                    </Form.Item>
                                </Col>
                            </>
                        }
                        <Col xs={24} sm={8}>
                            <Form.Item
                                name="location"
                            >
                                <Input placeholder='Location' />
                            </Form.Item>
                        </Col>


                    </Row>
                    <Row gutter={25}>
                        <Col xs={24}>
                            <Form.Item
                                name="description"
                            >
                                <Input.TextArea placeholder='Description' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={25}>
                        <Col xs={24}>
                            {userRolesInfo && userRolesInfo.roleName != "FM" &&
                                <Form.Item name="timeRanged">
                                    <div className='fs-myaccount-about-text'>

                                        <table className='border'>

                                            <thead>
                                                <tr ><td aria-colspan={2}>
                                                    <Title level={2}>Time Availability</Title>
                                                </td></tr>
                                            </thead>
                                            {weekDayWithTime.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td scope='row'>
                                                            {item.day}
                                                        </td>
                                                        <td>
                                                            <Form.Item key={item.day}
                                                                name={item.day}
                                                            >
                                                                <TimePicker.RangePicker
                                                                    use12Hours
                                                                    // value={[moment('2:00:00 AM','h:mm:ss A'), moment('10:00:00 PM','h:mm:ss A')]}
                                                                    format="h:mm:ss A"
                                                                    onChange={(value, dateString) => {
                                                                        console.log('Time', value, dateString);
                                                                        return (dateString);
                                                                    }}
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                // defaultValue={moment('13:30:56', 'HH:mm:ss')}
                                                                />
                                                            </Form.Item>
                                                        </td>
                                                    </tr>)

                                            })}
                                        </table>
                                        <Input.Group>   </Input.Group>


                                    </div>
                                </Form.Item>
                            }
                        </Col>
                    </Row>


                    <Row gutter={25}>
                        <Col xs={24} sm={24}>
                            <div className='fs-upload-gallery'>
                                <Form.Item
                                    name="picture"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please upload image',
                                        },
                                    ]}

                                >
                                    <Upload
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture"
                                        fileList={fileList}
                                        maxCount={1}
                                        onChange={handleChangeImage}
                                        beforeUpload={(file) => {
                                            return false
                                        }
                                        }
                                    >
                                        <Button className="ant-btn ant-btn-primary">Upload Ad Image</Button>

                                    </Upload>
                                </Form.Item>

                            </div>
                        </Col>
                    </Row>
                    <div className='fs-btn-group'>
                        <Button type="primary" htmlType="submit" loading={isSubmitting} className='fs-mr-15 fs-black'>Save</Button>
                        <Button type="primary" onClick={onUpdate}>Cancel</Button>
                    </div>
                </Form>
            </div>

        </div >
    );
}

export default AddAds;