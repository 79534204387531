import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import OpenNotification from "../../../components/Notifications";
import useToken from "../../../hooks/useToken";
import interceptor from "../../../shared/interceptor";

export const getHomePageDetail = createAsyncThunk(
    "homes/get",
    async (object, { dispatch, getState }) => {
        dispatch(gettingResponse())
        interceptor.axiosGet("get", "/homes/get", object, {}).then(res => {
            if (res.status == 200) {
                dispatch(gettingResponseSuccess(res.data.result))

            } else {
                dispatch(gettingResponseSuccess({}))
                OpenNotification("topRight", res.message)
            }
        }).catch(error => {
            dispatch(gettingResponseError(error))
            OpenNotification("topRight", error)

        })

    },
);


const homePageDataSlice = createSlice({
    name: "homes",
    initialState: {

        homePageData: null,
        status: "idle",
        isLoadinghomePage: false,
        error: null,
        isError: false
    },
    reducers: {
        gettingResponseError: (state, action) => {
            state.status = "error";
            state.homePageData = null;
            state.error = action.payload;
            state.isLoadinghomePage = false;
            state.isError = true;

        },
        gettingResponseSuccess: (state, action) => {

            state.status = "success";
            state.homePageData = action.payload;
            state.isLoadinghomePage = false;
            state.isError = false;
        },
        gettingResponse: (state, action) => {
            state.status = "loading";
            state.isLoadinghomePage = true;
            state.homePageData = null;
            state.isError = false;
        },
    },

});


// Action creators are generated for each case reducer function
export const { gettingResponse, gettingResponseError, gettingResponseSuccess } =
    homePageDataSlice.actions;

export default homePageDataSlice.reducer;
