// Libraries
import React from 'react';
import { Select, Typography, Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import { Plus, X } from 'react-feather';

// Component

// Styles
import './style.css';

// Assets

const { Option } = Select;
const { Title } = Typography;

const AddVideo = () => {

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='fs-my-account fs-videos'>
            <div className='fs-about-head'>
                <Title level={2}>Add Video</Title>
                {/* <div className='fs-circle-btn'>
                    <div to="#" className='fs-round-btn-large ant-btn ant-btn-primary'>
                        <X size={24} />
                    </div>
                </div> */}
            </div>
            <div className='fs-add-video'>
                <Form
                    name="myAccountAboutForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <ul className='fs-about-list'>
                        <li>
                            <div className='fs-about-title'>
                                <Form.Item
                                    name="videoLink"
                                    className='fs-mb-0'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input video link',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Please input video link' />
                                </Form.Item>
                            </div>
                            <div className='fs-about-end'>
                                <Form.Item
                                    name="videoCategory"
                                    className='fs-mb-0'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select video category',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select video category"
                                        allowClear
                                    >
                                        <Option value="jack">Jack</Option>
                                        <Option value="lucy">Lucy</Option>
                                        <Option value="Yiminghe">yiminghe</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        </li>
                    </ul>
                    <div className='fs-btn-group fs-mt-20'>
                        <Button type="primary" htmlType="submit" className='fs-mr-15 fs-black'>Add</Button>
                        <Button type="primary" htmlType="submit">Cancel</Button>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default AddVideo;