// Libraries
import React, { useEffect, useState } from 'react';
import { Typography, Image } from 'antd';
import Slider from "react-slick";
import { ArrowLeft, ArrowRight } from 'react-feather';

// Component

// Styles
import './style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Assets
import WeightIcon from '../../assets/images/weight.svg';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';
import { useShareDispatch, useShareSelector } from '../../shared';

const { Title } = Typography;

const Sponsors = (props) => {


    const [isLoadingDetail, setIsLoadingDetail] = useState(false);
    const { sponsorData } = useShareSelector(state => state.sponsorData)
    const dispatcher = useShareDispatch();




    useEffect(() => {
        if (sponsorData) {
            console.log(sponsorData)
        } else {
            dispatcher(actionsApi.getAllActiveSponsers())

        }
    }, [sponsorData])


    var settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: <div className='fs-slick-arrows'><ArrowLeft size={18} /></div>,
        nextArrow: <div className='fs-slick-arrows'><ArrowRight size={18} /></div>,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    return (
        <div className='fs-sponsors fs-section fs-slick-arrows'>
            <div className='fs-container'>
                <div className='fs-block-header'>
                    <Image preview={false} src={WeightIcon} alt="" />
                    <Title level={1}>{props.title}</Title>
                </div>
                <Slider {...settings} className="fs-slick-slider">

                    {sponsorData && sponsorData.map(item => {
                        return (
                            < div className='fs-sponsors-slide'>
                                <Image preview={false} src={item.path} alt="" />
                            </div>
                        )
                    })
                    }

                    {/* <div className='fs-sponsors-slide'>
                        <Image preview={false} src={props.brandImg} alt="" />
                    </div>
                    <div className='fs-sponsors-slide'>
                        <Image preview={false} src={props.brandImg} alt="" />
                    </div>
                    <div className='fs-sponsors-slide'>
                        <Image preview={false} src={props.brandImg} alt="" />
                    </div>
                    <div className='fs-sponsors-slide'>
                        <Image preview={false} src={props.brandImg} alt="" />
                    </div> */}
                </Slider>
            </div >
        </div >
    )
}

export default Sponsors;