import React from 'react';

export const EmailIcon = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.6125 4.23356C0.6655 4.17206 4.24 0.724558 4.24 0.724558C4.71115 0.262306 5.34524 0.00397343 6.00528 0.00537678C6.66533 0.00678013 7.29832 0.267806 7.7675 0.732058C7.7675 0.732058 11.3345 4.17206 11.387 4.23206L7.0605 8.55906C6.77465 8.83154 6.3949 8.98354 6 8.98354C5.60509 8.98354 5.22534 8.83154 4.9395 8.55906L0.6125 4.23356ZM7.7675 9.26806C7.29713 9.73382 6.66194 9.9951 6 9.9951C5.33805 9.9951 4.70286 9.73382 4.2325 9.26806L0.1145 5.15006C0.0426249 5.37799 0.00405981 5.6151 0 5.85406L0 9.50006C0.000793928 10.1629 0.264441 10.7983 0.73311 11.267C1.20178 11.7356 1.8372 11.9993 2.5 12.0001H9.5C10.1628 11.9993 10.7982 11.7356 11.2669 11.267C11.7356 10.7983 11.9992 10.1629 12 9.50006V5.85406C11.9959 5.6151 11.9574 5.37799 11.8855 5.15006L7.7675 9.26806Z" />
    </svg>
)

export const PhoneIcon = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.80648 6.424L11.939 9.5565L10.3535 11.142C10.0772 11.4172 9.74891 11.6347 9.38781 11.782C9.02671 11.9293 8.63995 12.0034 8.24998 12C4.62598 12 -2.45196e-05 7.374 -2.45196e-05 3.75C-0.00319618 3.35999 0.0710014 2.97322 0.218275 2.61208C0.365549 2.25093 0.582976 1.92257 0.857976 1.646L2.44348 0.061L5.57598 3.1935L3.51348 5.256C4.12664 6.71314 5.28426 7.87345 6.73998 8.49L8.80648 6.424ZM11 5H12C11.9985 3.67436 11.4713 2.40344 10.5339 1.46607C9.59654 0.528707 8.32562 0.0014558 6.99998 0V1C8.06048 1.00119 9.0772 1.423 9.82709 2.17289C10.577 2.92277 10.9988 3.9395 11 5ZM8.99998 5H9.99998C9.99919 4.20459 9.68286 3.44199 9.12042 2.87956C8.55799 2.31712 7.79539 2.00079 6.99998 2V3C7.53041 3 8.03912 3.21071 8.41419 3.58578C8.78927 3.96086 8.99998 4.46956 8.99998 5Z" />
    </svg>
)

export const PinIcon = () => (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 0C4.45343 0.00169843 2.97068 0.616825 1.87709 1.71042C0.783493 2.80401 0.168366 4.28676 0.166668 5.83333C0.166668 8.9145 5.06667 13.1442 5.62317 13.6167L6 13.9352L6.37683 13.6167C6.93333 13.1442 11.8333 8.9145 11.8333 5.83333C11.8316 4.28676 11.2165 2.80401 10.1229 1.71042C9.02932 0.616825 7.54658 0.00169843 6 0V0ZM6 8.75C5.42314 8.75 4.85923 8.57894 4.37959 8.25845C3.89994 7.93796 3.52611 7.48244 3.30535 6.94949C3.0846 6.41654 3.02684 5.8301 3.13938 5.26432C3.25192 4.69854 3.5297 4.17884 3.93761 3.77094C4.34551 3.36304 4.86521 3.08525 5.43099 2.97271C5.99677 2.86017 6.58321 2.91793 7.11616 3.13868C7.64911 3.35944 8.10463 3.73328 8.42512 4.21292C8.74561 4.69256 8.91667 5.25647 8.91667 5.83333C8.91574 6.6066 8.60815 7.34792 8.06137 7.8947C7.51459 8.44149 6.77326 8.74907 6 8.75Z" />
        <path d="M6 7.58325C6.9665 7.58325 7.75 6.79975 7.75 5.83325C7.75 4.86675 6.9665 4.08325 6 4.08325C5.0335 4.08325 4.25 4.86675 4.25 5.83325C4.25 6.79975 5.0335 7.58325 6 7.58325Z" />
    </svg>
)