// Libraries
import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Select, Button, Image, Table } from 'antd';
import { clientPreference, fitnessCenterArray, SpecialtiesArray } from '../../../../data/fitnessCenters';
import domainurl from '../../../../constants/domainurl';

// Component

// Styles
import './style.css';

// Assets
import image from '../../../../assets/images/hero.jpg';
import { Link } from 'react-router-dom';
import useToken from '../../../../hooks/useToken';
import { useMyAccountContext } from '../../../../context/MyAccount/MyAccount';


const { Option } = Select;

const columns = [
    {
        title: 'Fitness Pros',
        key: 'imagePath',
        render: (value) => <Image preview={false} src={value && value.imagePath ? value.imagePath : image} alt="" />
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => <Link to={`/public-profile/pro/${record.userRolesInfo}`}>{text}</Link>,

    },
    {
        title: 'Languages',
        dataIndex: 'languages',
        key: 'languages',
    },
    {
        title: 'Specialties',
        dataIndex: 'specialties',
        key: 'specialties',
    },
    // {
    //     title: 'Rating',
    //     dataIndex: 'rating',
    //     key: 'rating',
    // },
];


const searchObjectData = {
    name: "",
    specialities: [],
    language: "",
    clientPreference: "",
    gender: "",
    location: "",
    onLineliveTraining: "",
    loginUserId: ''
};

const FitnessPros = () => {
    const [id, setId] = useState(null)
    const { userLoginObject } = useToken();   
     const [form] = Form.useForm();

    useEffect(() => {
        if (userLoginObject) {
            let defaultReole = userLoginObject.data.defaultRole;
            const filteredResult = userLoginObject && userLoginObject.data.userRolesInfo && userLoginObject.data.userRolesInfo.find((e) => e.role == defaultReole);
            searchObjectData.loginUserId = filteredResult._id
        }
        searchApi(searchObjectData);
    }, [""]);

    const onFinish = (values) => {

        if (values.name == undefined) {
            values.name = "";
        }
        if (values.specialities == undefined) {
            values.specialities = [];
        }
        if (values.language == undefined) {
            values.language = "";
        }
        if (values.clientPreference == undefined) {
            values.clientPreference = "";
        }
        if (values.gender == undefined) {
            values.gender = "";
        }
        if (values.location == undefined) {
            values.location = "";
        }
        if (values.onLineliveTraining == undefined) {
            values.onLineliveTraining = "";
        }
        console.log('Success:', values);
        searchApi(values);
    };

    const [isLoading, setIsLoading] = useState(false);
    const [list, setlist] = useState([]);
    const searchApi = (data) => {
        setIsLoading(true)
        fetch(domainurl + '/users/getFPUserList', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status == "ok") {
                    setlist(res.result);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='fs-fitness'>
            <div className='fs-container'>
                <Form
                    name="fitnessProForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                >
                    <Row gutter={25}>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="name"
                            >
                                <Input placeholder='Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="specialities"
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Specialties"
                                    showArrow
                                    allowClear
                                >
                                    {SpecialtiesArray.map((item) => (

                                        <>
                                            {
                                                item.name !== 'Others' && item.name !== 'All' &&
                                                <Select.Option key={item.key} value={item.name}>
                                                    {item.name}
                                                </Select.Option>
                                            }
                                        </>

                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="location"
                            >
                                <Input placeholder='Location' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="clientPreference"
                            >
                                <Select
                                    // mode="multiple"
                                    placeholder="Corporate Fitness"
                                    showArrow
                                    allowClear
                                // value={selectedRole}

                                // onChange={handleRole}
                                >
                                    {clientPreference.map((item) => (
                                        <>
                                            {
                                                item.center !== 'Others' &&

                                                <Select.Option key={item.key} value={item.key}>
                                                    {item.center}
                                                </Select.Option>
                                            }
                                        </>

                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="gender"
                                allowClear
                            >
                                <Select
                                    placeholder="Gender"
                                >
                                    <Option value="1">Male</Option>
                                    <Option value="2">Female</Option>
                                    <Option value="3">Other</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="onLineliveTraining"
                            >
                                <Select
                                    placeholder="Online Live Training "
                                    allowClear
                                >
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='fs-right'>
                        <Button type="default" className='fs-mr-15' onClick={() => { form.resetFields(); }}>Reset</Button>
                        <Button type="primary" htmlType="submit">Search Fitness Pros</Button>
                    </div>
                </Form>
                <Table
                    columns={columns}
                    dataSource={list}
                    loading={isLoading}
                    scroll={{
                        x: 800,
                    }}
                    className="fs-pros" />
            </div>
        </div>
    );
}

export default FitnessPros;